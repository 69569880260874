import {
  faCreditCard,
  faFileAlt,
  faMoneyBillAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBan,
  faBarcode,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import "./style.css";

import DateHelper from "../../../Helpers/date";
import sadSmille from "../../../assets/img/sad-smille.png";
import Loader from "../../../components/Loader";
import {
  orderStatus,
  paymentTypeDictionary,
} from "../../../services/constants";
import NewApi from "../../../services/new-api";
import MaskHelper from "../../../Helpers/mask";
import { useTranslation } from "react-i18next";
import sendToLogger from "../../../Helpers/errorLogger";

export default function MeusPedidos() {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);

  const changeArrow = (idx) => {
    let arrowUp = document.getElementById(idx + "up");
    let arrowDow = document.getElementById(idx + "dw");
    let header = document.getElementById(idx + "hd");
    let card = document.getElementById(idx + "cd");
    var hidden = arrowUp.hasAttribute("hidden");
    if (hidden) {
      arrowUp.removeAttribute("hidden");
      arrowDow.setAttribute("hidden", true);
      header.classList.add("order-opened");
      card.classList.add("card-opened");
      card.classList.remove("card-acordion");
    } else {
      arrowUp.setAttribute("hidden", true);
      arrowDow.removeAttribute("hidden");
      header.classList.remove("order-opened");
      card.classList.remove("card-opened");
      card.classList.add("card-acordion");
    }
  };

  const loadMoreOrders = () => {
    const footer = document.getElementById("copyright");
    const footerSize = footer.getBoundingClientRect();
    const isAtTheBottom = footerSize.top <= window.innerHeight;

    if (isAtTheBottom && hasMorePages) {
      setLoading(true);
      setScrollPosition(document.documentElement.scrollHeight);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const carregaDados = () => {
    new NewApi()
      .get("orders", { params: { page: currentPage, per_page: 15 } })
      .then((res) => {
        if (res.data?.data?.length < 15) {
          setHasMorePages(false);
        }
        setOrders((prevOrders) => [...prevOrders, ...res.data.data]);
        setLoading(false);
      })
      .catch((err) => {
        sendToLogger(err);
      })
      .finally(() => {
        window.scrollTo(0, scrollPosition);
      });
  };

  const checkStatus = {
    finalizado: "badge badge-pill badge-soft-success",
    expirado: "badge badge-pill badge-soft-secondary",
    pendente: "badge badge-pill badge-soft-warning",
    estornado: "badge badge-pill badge-soft-danger",
    revertida: "badge badge-pill badge-soft-danger",
    "não pagou": "badge badge-pill badge-soft-danger",
    "pré-autorizada": "badge badge-pill badge-soft-dark",
  };

  function checkTypePayment(order) {
    switch (order) {
      case "Boleto":
        return (
          <span>
            <FontAwesomeIcon icon={faBarcode} />
          </span>
        );
      case "Cartão de crédito":
        return (
          <span>
            <FontAwesomeIcon icon={faCreditCard} />
          </span>
        );
      case "Dinheiro":
        return (
          <span>
            <FontAwesomeIcon icon={faMoneyBillAlt} />
          </span>
        );
      case "Cortesia":
        return (
          <span className="text-muted">
            <FontAwesomeIcon icon={faBan} />
          </span>
        );
      default:
        return <span>{order}</span>;
    }
  }

  useEffect(() => {
    carregaDados();

    window.addEventListener("scroll", loadMoreOrders);
    return () => {
      window.removeEventListener("scroll", loadMoreOrders);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div id="page-myorders" className="container mt-4">
        <h2>{t("MyOrders.myOrders")}</h2>
        <div id="orders-card" className="card">
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <div key={`card-${index}-${order.id}`}>
                {!(order.payment_type === "Não realizado") ? (
                  <div className="row">
                    <div className="col-md-12">
                      <React.Fragment>
                        <Accordion>
                          <Card
                            className="card-acordion card-myorders"
                            id={order.id + "cd"}
                          >
                            <Card.Header
                              className="order-card-header row"
                              id={order.id + "hd"}
                            >
                              <span className="order-eventdetail col-md-4">
                                {order.token_ticket} - {order.event.name}
                              </span>
                              <span className="col-md-2">
                                {DateHelper.format(
                                  order.created_at,
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </span>

                              <div className="col-md-1 text-center order-icon-payment">
                                {checkTypePayment(order.payment_type)}
                              </div>

                              <div className="col-md-1 text-center">
                                <span className={checkStatus[order.status]}>
                                  {orderStatus(order.status, t)}
                                </span>
                              </div>

                              <div className="col-md-2">
                                {MaskHelper.numberToLocaleCurrency(
                                  order.total_value
                                )}
                              </div>

                              <Accordion.Toggle
                                className="col-md-2"
                                as={Button}
                                variant="link"
                                eventKey="0"
                                onClick={(e) => changeArrow(order.id)}
                              >
                                {t("MyOrders.viewDetails")}
                                <i
                                  className="fa fa-angle-up fa-lg arrows"
                                  id={order.id + "up"}
                                  hidden
                                ></i>
                                <i
                                  className="fa fa-angle-down fa-lg arrows"
                                  id={order.id + "dw"}
                                ></i>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                {order.payment_type === "Boleto" &&
                                order.status === "pendente" ? (
                                  <>
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={order.url_billet}
                                    >
                                      <FontAwesomeIcon icon={faFileAlt} />{" "}
                                      {t("MyOrders.visualizeBill")}
                                    </a>
                                    <hr></hr>
                                  </>
                                ) : null}

                                <div>
                                  {t("MyOrders.paymentType")}{" "}
                                  {t(
                                    `MyTickets.${
                                      paymentTypeDictionary[order.payment_type]
                                    }`
                                  )}
                                </div>
                                <div>
                                  {t("MyOrders.totalValue")}{" "}
                                  {MaskHelper.numberToLocaleCurrency(
                                    order.total_value
                                  )}
                                </div>
                                <hr></hr>

                                {/* DADOS DOS PARTICIPANTES */}
                                {order.tickets?.map((tict, idx) => (
                                  <div className="row" key={idx}>
                                    <div className="col-md-12">
                                      {order.status === "finalizado" ? (
                                        <span>
                                          <FontAwesomeIcon
                                            icon={faTicketAlt}
                                            className="mr-2"
                                          />
                                          <b>{tict.code}</b> -&nbsp;
                                        </span>
                                      ) : null}
                                      <span>
                                        {tict.ticket_name}&nbsp;
                                        <b>{t("MyTickets.participant")}</b>
                                        &nbsp;
                                        {tict.attendee_name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </React.Fragment>
                    </div>
                  </div>
                ) : null}
              </div>
            ))
          ) : (
            <>
              <div className="empty-tickets text-center p-4">
                <h3 className="">{t("MyOrders.noOrdersPlaced")}</h3>
                <img src={sadSmille} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
