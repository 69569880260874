import React, { useState, useEffect } from "react";
import Loader from "../../../components/Loader";
import NewApi from "../../../services/new-api";
import "./index.css";

import question from "../../../assets/img/skip.png";
import { useTranslation } from "react-i18next";

export default function Index({ history }) {
  const [faqs, setfaqs] = useState([{ id: "", pergunta: "", resposta: "" }]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function getFaqs() {
    setLoading(true);

    const query = {};
    const category = localStorage.getItem("TIPE_FAQ");

    if (category) {
      query.by_category = category;
    }

    new NewApi()
      .get("faqs", { params: query })
      .then(
        (res) => setfaqs(res.data.data),
        () => {}
      )
      .finally(() => setLoading(false));
  }

  function redirectSingleFac(id) {
    history.push("/faqs/" + id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getFaqs();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <div id="faqs" className="text-center">
          <div className="container">
            <h3>{t("FAQs.frequentQuestions")}</h3>
            {faqs.map((fac) => (
              <div
                className="col-12 blocks"
                key={fac.id}
                onClick={() => redirectSingleFac(fac.id)}
              >
                <p>{fac.question}</p>
                <img src={question} alt="" />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
