import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import NewApi from "../../../services/new-api";

import "./index.css";
import sendToLogger from "../../../Helpers/errorLogger";
import { useTranslation } from "react-i18next";

export default function Index() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [faq, setFaq] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    new NewApi()
      .get(`faqs/${id}`)
      .then((res) => setFaq(res.data))
      .catch((error) => {
        sendToLogger(error);
      })
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <>
          <div id="faq-nav" className="col-12">
            <div className="container">
              <NavLink to="/faqs">{t("Footer.faq")}</NavLink>
              <span>
                {" > "}
                {faq.question}
              </span>
            </div>
          </div>
          <div className="container mt-4 mb-4 single-faq">
            <h1>{faq.question}</h1>
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{ __html: faq.answer }}
            ></div>
          </div>
        </>
      )}
    </>
  );
}
