import { Col, Row } from "react-bootstrap";
import MaskHelper from "../../Helpers/mask";
import {
  calculateDiscountTotal,
  calculateFeeDiscount,
} from "../../Helpers/checkout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import React from "react";
import TicketSummaryView from "./TicketsListView";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";

const OrderSummaryView = ({
  orderData,
  discountCode,
  discountType,
  installments,
  discountValue,
  paymentMethod,
  installmentList,
}) => {
  const isMobile = useWindowSize();
  const { t } = useTranslation();
  if (!orderData) {
    return;
  }

  return (
    <Row className="order-resume">
      <Col>
        <div className="card">
          <div className="card-header">{t("OrderSummaryView.orderResume")}</div>
          <div className="card-body">
            <Row className="order-checkout">
              <TicketSummaryView
                tickets={orderData.tickets}
                singleLine
                groupByName
                discountInfo={{
                  type: discountType,
                  value: discountValue,
                }}
                addTicketFee={false}
                showPriceDetails={false}
                showTicketDivider={false}
                showPassportDetails={false}
              />
              <Col
                xs={12}
                className="order-items order-items-without-divider order-items-fixed-line pb-1 pt-0"
              >
                <Row>
                  <Col xs={8}>
                    <span>{t("OrderSummaryView.serviceFee")}</span>
                  </Col>

                  <Col xs={4} className="text-right">
                    {MaskHelper.numberToLocaleCurrency(
                      discountCode
                        ? calculateFeeDiscount(
                            discountType,
                            discountValue,
                            (orderData.tickets || []).reduce(
                              (acc, item) => acc + item.value,
                              0
                            ),
                            (orderData.tickets || []).reduce(
                              (acc, item) => acc + item.fee,
                              0
                            )
                          )
                        : (orderData.tickets || []).reduce(
                            (acc, item) => acc + item.fee,
                            0
                          )
                    )}
                  </Col>
                </Row>
              </Col>
              {!!orderData.processing_fee && (
                <Col
                  xs={12}
                  className="order-items order-items-without-divider order-items-fixed-line pb-1 pt-0"
                >
                  <Row>
                    <Col xs={8}>
                      <span>{t("OrderSummaryView.proccessingFee")}</span>
                      <span data-tip="Taxa de processamento de meios de<br/>pagamento via cartão de crédito.<br/>Em PIX ou outros pagamentos à vista,<br/>não há incidência dessa taxa.">
                        <ReactTooltip
                          html
                          place={isMobile ? "bottom" : "right"}
                          effect="solid"
                        />
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          size="1x"
                          className="processing-exclamation-icon"
                        />
                      </span>
                    </Col>

                    <Col xs={4} className="text-right">
                      {MaskHelper.numberToLocaleCurrency(
                        orderData.processing_fee / 100.0 || 0
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
              {discountCode && discountType === 1 && discountValue !== 100 && (
                <Col
                  xs={12}
                  className="order-items order-items-without-divider order-items-fixed-line pb-1 pt-0"
                >
                  <Row>
                    <Col xs={6}>
                      <span>
                        {t("OrderSummaryView.discounts")} ({discountCode})
                      </span>
                    </Col>

                    <Col xs={6} className="text-right">
                      <span className="discount-coupon-applied">
                        -{" "}
                        {MaskHelper.numberToLocaleCurrency(
                          calculateDiscountTotal(
                            discountType,
                            discountValue,
                            (orderData.tickets || []).reduce(
                              (acc, item) => acc + item.value,
                              0
                            ),
                            (orderData.tickets || []).reduce(
                              (acc, item) => acc + item.fee,
                              0
                            )
                          )
                        )}
                      </span>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>

          <div className="card-footer">
            <Row>
              <Col xs={6}>{t("OrderSummaryView.total")}</Col>
              <Col xs={6} className="text-right">
                <p className="m-0">
                  {MaskHelper.numberToLocaleCurrency(
                    paymentMethod === "Cartão"
                      ? installmentList[installments - 1]?.total
                      : orderData.value +
                          (orderData?.processing_fee / 100.0 || 0)
                  )}
                </p>

                {paymentMethod === "Cartão" && (
                  <p className="m-0 font-weight-light">
                    {installments}
                    {t("OrderSummaryView.installments")}{" "}
                    {MaskHelper.numberToLocaleCurrency(
                      installmentList[installments - 1]?.installment
                    )}
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default OrderSummaryView;
