/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import creditCardType from "credit-card-type";
import dayjs from "dayjs";
import { list, sift, sort } from "radash";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import { useForm } from "react-hook-form";
import MetaTags from "react-meta-tags";
import { useHistory, useParams } from "react-router-dom";
import { Element, animateScroll as scroll } from "react-scroll";
import Swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import { pushDataLayerAndEvent, pushGAEvent } from "../../Helpers/tagManager";
import {
  validateCnpj,
  validateCpf,
  validatePhone,
} from "../../Helpers/validations";
import credit_card from "../../assets/svg/credit_card.svg";
import picpay from "../../assets/svg/picpay.svg";
import pix from "../../assets/svg/pix.svg";
import warning from "../../assets/svg/warning.svg";
import success from "../../assets/svg/success.svg";
import arrow from "../../assets/svg/arrow.svg";
import apple_pay from "../../assets/svg/apple_pay.svg";
import google_pay from "../../assets/svg/google_pay.svg";
import shield_security from "../../assets/svg/shield_security.svg";
import CompleteUserRegistration from "../../components/Complete";
import Loader from "../../components/Loader";
import { useAuth } from "../../hooks/useAuth";
import CheckoutApi from "../../services/checkout-api";
import {
  STATE_NAMES,
  findDescription,
  gatewayErrorMessagesByType,
  getDecodedCookie,
  setCookie,
  ticketNomenclatureInSingular,
} from "../../services/constants";
import KondutoService from "../../services/konduto";
import NewApi from "../../services/new-api";
import PagarMeService from "../../services/pagarme";
import PagseguroService from "../../services/pagseguro";
import { detectorOfOS, isInZigApp, round2 } from "../../services/utils";
import AcceptTermsView from "../../views/checkout/AcceptTermsView";
import CountDownView from "../../views/checkout/CountDownView";
import OrderSummaryView from "../../views/checkout/OrderSummaryView";
import TicketsSummaryView from "../../views/checkout/TicketsSummaryView";
import AcknownledgeTermsView from "../../views/checkout/AcknownledgeTermsView";

import AttendeeQuestionView from "../../views/checkout/AttendeeQuestionView";
import "./index.css";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";
import { useYuno } from "../../context/YunoContext";
import { useLocation } from "../../hooks/useLocation";

const newApi = new NewApi();
const checkoutApi = new CheckoutApi();
const pagseguroService = new PagseguroService();

const CARD_METHODS = ["Cartão", "Débito", "Crédito"];
const ADDRESS_METHODS = ["Dinheiro", "PicPay", "Cortesia"];
const WALLET_METHODS = ["Google Pay", "Apple Pay"];

const yunoPaymentTypes = {
  Cartão: "CARD",
  Débito: "CARD",
  PIX: "PIX",
  Boleto: "BOLETO",
  "Google Pay": "GOOGLE_PAY",
  "Apple Pay": "APPLE_PAY",
};

const paymentIcons = {
  Cartão: credit_card,
  Débito: credit_card,
  PicPay: picpay,
  PIX: pix,
  "Google Pay": google_pay,
  "Apple Pay": apple_pay,
};

const invalidCharacters = /^[^\d=@]+$/;

const accentuationAndHifen =
  /^(?:[a-zA-Z\u00C0-\u017F]+(?:[-\s](?:[a-zA-Z\u00C0-\u017F]+))*)$/;

const CheckoutNew = () => {
  const yuno = useYuno();
  const history = useHistory();
  const { token } = useParams();
  const { isLogged, user } = useAuth();
  const { t, i18n } = useTranslation();
  const { handleIPLocation } = useLocation();

  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const schema = yup.object().shape({
    attendees: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          email: yup
            .string()
            .required(t("Checkout.emailRequired"))
            .email(t("Checkout.emailInvalid"))
            .matches(
              /^[a-zA-Z]+(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              t("Checkout.emailInvalid")
            )
            .test(
              "unique",
              t("Checkout.duplicateAttendeeEmail"),
              (value, { from }) =>
                from[1].value.autofill_enabled ||
                from[1].value.attendees.filter(({ email }) => email === value)
                  .length === 1
            ),
          first_name: yup
            .string()
            .required(t("CommonExpressions.nameRequired"))
            .matches(
              invalidCharacters,
              t("CommonExpressions.nameInvalidCharacters")
            )
            .matches(
              accentuationAndHifen,
              t("CommonExpressions.typedCorrectly")
            )
            .min(3, t("CommonExpressions.nameInvalidLength"))
            .trim(),
          last_name: yup
            .string()
            .required(t("CommonExpressions.lastnameRequired"))
            .matches(
              invalidCharacters,
              t("CommonExpressions.lastnameInvalidCharacters")
            )
            .matches(
              accentuationAndHifen,
              t("CommonExpressions.typedCorrectly")
            )
            .min(3, t("CommonExpressions.lastnameInvalidLength"))

            .trim(),
          automatic_fill: yup.boolean().nullable(),
          passport_name: yup.string().nullable(),
          passport_index: yup.number().nullable(),
          seat_group_name: yup.string().nullable(),
          seat_id: yup.string().nullable(),
          seat_name: yup.string().nullable(),
          ticket: yup.string(),
          use_my_data: yup.boolean().nullable(),
          ticket_type_id: yup.number(),
          answers: yup
            .array()
            .nullable()
            .of(
              yup.object().shape({
                answer: yup
                  .string()
                  .when("required", {
                    is: 1,
                    then: (schema) =>
                      schema.required(t("Checkout.fieldRequired")),
                  })
                  .transform((value, originalValue) => {
                    if (typeof value === "string") {
                      return value;
                    }

                    return originalValue.length
                      ? JSON.stringify(originalValue)
                      : "";
                  }),
                required: yup.number(),
                ticket_id: yup.number(),
                event_form_id: yup.number(),
              })
            ),
        })
      )
      .default([]),
    payment: yup.object().shape({
      phone: yup
        .string()
        .nullable()
        .when("payment_mode", {
          is: () => !isZig(),
          then: (schema) =>
            schema
              .required(t("Checkout.phoneRequired"))
              .test("validate-phone", t("Checkout.phoneInvalid"), (value) =>
                validatePhone(value)
              ),
        }),
      document: yup
        .string()
        .nullable()
        .when("payment_mode", {
          is: () => !isZig(),
          then: (schema) =>
            schema
              .required(t("Checkout.documentRequired"))
              .test(
                "validate-document",
                t("Checkout.documentInvalid"),
                (document) => validateCpf(document) || validateCnpj(document)
              ),
        }),

      payment_type: yup.string(),
      payment_mode: yup.string().nullable(),
      card_cvv: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig(),
          then: (schema) => schema.required(t("Checkout.codeRequired")),
        }),
      card_number: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig(),
          then: (schema) => schema.required(t("Checkout.numberRequired")),
        }),
      card_holder: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig(),
          then: (schema) => schema.required(t("Checkout.printedNameRequired")),
        }),
      card_expiration: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => CARD_METHODS.includes(type) && !isZig(),
          then: (schema) => schema.required(t("Checkout.expirationRequired")),
        }),
      installments: yup.number(),
      zip_code: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.zipcodeRequired")),
        }),
      city: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.cityRequired")),
        }),
      neighborhood: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.neighborhoodRequired")),
        }),
      number: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.numberRequired")),
        }),
      state: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.stateRequired")),
        }),
      street: yup
        .string()
        .nullable()
        .when("payment_type", {
          is: (type) => !ADDRESS_METHODS.concat(WALLET_METHODS).includes(type),
          then: (schema) => schema.required(t("Checkout.addressRequired")),
        }),
      complement: yup.string().nullable(),
    }),
    autofill_enabled: yup.boolean().default(true),
  });

  const {
    watch,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: retrieveCookieData(),
  });

  const [data, setData] = useState();
  const [event, setEvent] = useState();
  const [cardCvv, setCardCvv] = useState(3);
  const [cardType, setCardType] = useState();
  const [orderData, setOrderData] = useState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [formQuestions, setFormQuestions] = useState();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [zipcodeFetched, setZipcodeFetched] = useState(false);
  const [fetchingZipcode, setFetchingZipcode] = useState(false);
  const [isYunoInitialized, setIsYunoInitialized] = useState(false);
  const [initializingYuno, setInitializingYuno] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [discountCoupon, setDiscountCoupon] = useState(() => {
    const discountCode = localStorage.getItem("code");
    if (discountCode) {
      return discountCode;
    }
    return null;
  });
  const isCodeAppliedSameAsIntegration = event?.discount?.some(
    (discount) => discount.code === discountCoupon
  );
  const showInformativeBox =
    event?.has_integration && isCodeAppliedSameAsIntegration;
  const isUserElegibleForDiscount = data?.discount_details?.by_integration;
  const integrationCouponDescription = findDescription(
    discountCoupon,
    event?.discount
  );

  const isZig = () => {
    const paymentMode = watch("payment.payment_mode");
    const gateway = event?.gateway || event?.default_acquirer;

    if (!paymentMode) {
      return gateway === "zig" ? true : false;
    } else {
      return paymentMode === "zig" ? true : false;
    }
  };

  const hasAtLeastOneZigPayment = () => {
    return (
      isZig() || data.methods.some(({ payment_mode }) => payment_mode === "zig")
    );
  };

  const os = detectorOfOS();

  useEffect(() => {
    const cartPromise = newApi
      .get(`orders/${token}`)
      .then(async ({ data }) => {
        if (data.expired) {
          Swal.fire({
            icon: "error",
            title: t("Checkout.timeout"),
            text: t("Checkout.timeoutMessage"),
            didClose: () => {
              localStorage.removeItem("order_token");
              history.push(
                `/eventos/${localStorage.getItem("slug") || data.event_slug}`
              );
            },
          });

          return;
        }

        const eventInfo = await newApi
          .get(`events/${localStorage.getItem("slug") || data.event_slug}`)
          .then(({ data }) => {
            setEvent(data);

            if (data.g_analytics_code) {
              ReactGA4.gtag("config", data.g_analytics_code);
            }

            return data;
          });

        const attendees = [];
        const questions = sort(
          data.custom_fields,
          ({ order_field }) => order_field
        );
        const firstMethod = data.methods[0];

        setData(data);
        setOrderData(firstMethod);
        setFormQuestions(questions);

        setValue(
          "autofill_enabled",
          !!eventInfo.enable_attendees_checkout_autofill
        );

        if (!watch("attendees")) {
          firstMethod.tickets.forEach((item, index) => {
            attendees.push({
              id: item.id,
              ticket: item.name,
              first_name:
                item.passport_id || index === 0 ? user.first_name : "",
              last_name: item.passport_id || index === 0 ? user.last_name : "",
              email: item.passport_id || index === 0 ? user.email : "",
              passport_name: item.passport_name,
              seat_name: item.seat_name,
              seat_group_name: item.seat_group_name,
              seat_id: item.seat_id,
              ticket_type_id: item.ticket_type_id,
              automatic_fill: !!item.passport_id || index === 0,
              use_my_data: !!item.passport_id || index === 0,
              passport_index: item.passport_index,
              answers: questions.map(({ id, required, field_type }) => ({
                answer: "",
                required: required,
                ticket_id: item.id,
                event_form_id: id,
              })),
            });
          });

          setValue("attendees", attendees);
        }

        if (!watch("payment.installments")) {
          setValue("payment.installments", 1);
        }

        if (data.methods.length === 1 && firstMethod.value === 0) {
          setValue("payment.payment_type", "Cortesia");
        } else if (!watch("payment.payment_type")) {
          setValue("payment.payment_type", firstMethod.method);
          setValue("payment.payment_mode", firstMethod.payment_mode);
        }

        if (data.fees) {
          const processingFee =
            data.methods.find(({ processing_fee }) => !!processing_fee)
              ?.processing_fee || 0;

          const maxInstallments = Object.keys(data.fees.installments).length;
          const installments = [];
          const orderTotal = firstMethod.value + processingFee / 100.0;

          for (let index = 0; index < maxInstallments; index++) {
            let total = 0;

            if (data.fees.type === "composto") {
              total = round2(
                orderTotal *
                  (1 +
                    (data.fees.installments[(index + 1).toString()] / 100) *
                      Math.max(index, 1))
              );
            } else {
              total =
                orderTotal *
                (1 + data.fees.installments[(index + 1).toString()] / 100);
            }

            installments.push({
              total,
              installment: round2(total / (index + 1)),
            });
          }

          setInstallments(installments);
        }

        pushGAEvent("begin_checkout", {
          currency: "BRL",
          value: firstMethod?.value,
          coupon: data.discount_code,
          items: firstMethod.tickets.map((item) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.value + item.fee,
            quantity: 1,
          })),
        });

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user?.id,
            actions: [
              {
                action: "Begin Checkout",
                attributes: {
                  currency: "BRL",
                  value: firstMethod?.value,
                  coupon: data.discount_code,
                  items: firstMethod.tickets.map((item) => ({
                    item_id: item.id,
                    item_name: item.name,
                    price: item.value + item.fee,
                    quantity: 1,
                  })),
                },
                platform: "web",
              },
            ],
          });
        }
      })
      .catch((error) => {
        sendToLogger(error);

        Swal.fire({
          icon: "error",
          title: t("Checkout.orderNotFound"),
          text: t("Checkout.orderNotFoundMessage"),
          didClose: () => {
            history.push(
              `/eventos/${localStorage.getItem("slug") || data.event_slug}`
            );
          },
        });
      });

    const scriptPromise = pagseguroService.loadScript();

    Promise.all([cartPromise, scriptPromise]).finally(() => {
      setLoadingPage(false);
    });

    KondutoService.sendEvent("page", "checkout");
  }, [token]);

  useEffect(() => {
    if (!watch() || !data) {
      return;
    }

    const cookieData = getDecodedCookie("checkoutInfo", token);

    if (!cookieData) {
      setCookie("checkoutInfo", { code: token }, 900);
      return;
    }

    const updatedCookieData = {
      ...cookieData,
      ...watch(),
    };

    setCookie("checkoutInfo", updatedCookieData, data.time_to_expire);

    const cardNumber = watch("payment.card_number");
    const paymentType = watch("payment.payment_type");

    if (!!cardNumber) {
      const cards = creditCardType(cardNumber);

      if (cards.length) {
        setCardType(cards[0].type);
        setCardCvv(cards[0].code.size);
      } else {
        setCardType(null);
        setCardCvv(3);
      }
    } else {
      setCardType(null);
      setCardCvv(3);
    }

    if (!!paymentType) {
      const findMethod = data.methods.find(({ method }) => method === paymentType)
      if (findMethod) {
        setOrderData(findMethod);
      }
      
    }
  }, [watch()]);

  useEffect(() => {
    async function sendPixelAndADS() {
      if (event?.fb_pixel_id) {
        await trackingEventToPixel("AddPaymentInfo");
      }

      if (event?.g_id_ads) {
        ReactGA4.gtag("config", event.g_id_ads);
      }
    }

    sendPixelAndADS();
  }, [event]);

  useEffect(() => {
    const value = watch("payment.zip_code");

    if (MaskHelper.numberMask(value)?.length >= 8) {
      setFetchingZipcode(true);

      axios
        .get(`https://viacep.com.br/ws/${value}/json/`, { timeout: 5000 })
        .then(({ data }) => {
          if (!data.error) {
            setValue("payment.city", data.localidade);
            setValue("payment.state", data.uf);
            setValue("payment.street", data.logradouro);
            setValue("payment.neighborhood", data.bairro);
          }
        })
        .catch(() => {})
        .finally(() => {
          setZipcodeFetched(true);
          setFetchingZipcode(false);

          clearErrors("payment.zip_code");
          clearErrors("payment.street");
          clearErrors("payment.state");
          clearErrors("payment.neighborhood");
          clearErrors("payment.number");
          clearErrors("payment.city");
        });
    }
  }, [watch("payment.zip_code")]);

  useEffect(() => {
    if (!loadingPage && data) {
      scroll.scrollToTop();
    }
  }, [loadingPage, data]);

  useEffect(() => {
    const initializeYuno = async () => {
      if (!isYunoInitialized && data && hasAtLeastOneZigPayment()) {
        setInitializingYuno(true);
        await initCheckoutLite();
        if (CARD_METHODS.includes(watch("payment.payment_type"))) {
          mountYunoCheckout();
        }
      }
    };

    initializeYuno();
  }, [data, isYunoInitialized, isZig]);

  useEffect(() => {
    if (isYunoInitialized) {
      setInitializingYuno(true);
      yuno.unmount();
      yuno.unmountSdk();
      initCheckoutLite().then(() => {
        mountYunoCheckout();
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    async function handleUserData() {
      if (Object.keys(userData).length === 0) {
        await handleIPLocation();
      }
    }

    if (sessionStorage.getItem("newCheckoutSession")) {
      const currentCheckoutSession = sessionStorage.getItem("newCheckoutSession")
      sessionStorage.clear();
      sessionStorage.setItem("newCheckoutSession", currentCheckoutSession)
    } else {
      sessionStorage.clear();
    }

    handleUserData();
  }, []);

  useEffect(() => {  
    sessionStorage.clear()
  }, []);

  function retrieveCookieData() {
    const data = getDecodedCookie("checkoutInfo", token);

    if (data) {
      const autofill =
        data.autofill_enabled === undefined ? true : data.autofill_enabled;

      return { ...data, autofill_enabled: autofill };
    } else {
      return {};
    }
  }

  async function finalizeCardByTimeOut() {
    pushGAEvent("timeout_checkout");

    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "Timeout Checkout",
            platform: "web",
          },
        ],
      });
    }

    Swal.fire({
      icon: "error",
      title: t("Checkout.timeout"),
      text: t("Checkout.timeoutMessage"),
      didClose: () => {
        history.push(`/eventos/${localStorage.getItem("slug")}`);
      },
    });
  }

  function handleToggleExpand() {
    setIsExpanded(!isExpanded);
  }

  function triggerEventGA4AndADS(value) {
    const orderInfo = data.methods.find(
      ({ method }) => method === watch("payment.payment_type")
    );

    pushDataLayerAndEvent({
      tkt_event_name: event.name,
      event_local: event.event_location.name,
      event_produced_by: event.producer.name,
      event_date: dayjs(data.start_date).format("DD/MM/YYYY"),
      user_id: user.id,
      is_buyer: user.is_buyer,
      active_buyer: user.active_buyer,
      last_order_ago: user.last_order_ago,
    });

    pushGAEvent("purchase", {
      tax: orderInfo.tickets.reduce((acc, value) => acc + value.fee, 0),
      value: orderInfo.value,
      coupon: data.discount_code,
      currency: "BRL",
      transaction_id: data.token,
      items: orderInfo.tickets.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: (item.value + item.fee).toFixed(2),
        quantity: 1,
      })),
    });

    if (event.g_id_ads && event.g_conversion_snippet_ads) {
      pushGAEvent("conversion", {
        send_to: `${event.g_id_ads}/${event.g_conversion_snippet_ads}`,
        value,
        currency: "BRL",
      });
    }
  }

  async function sendData(
    formData,
    oneTimeToken,
    tokenWithInformation,
    session
  ) {
    setLoadingPayment(true)
    const { payment, attendees } = formData;
    const checkoutSession =
      sessionStorage.getItem("newCheckoutSession") || session;

    const orderInfo = data.methods.find(
      ({ method }) => method === payment.payment_type
    );

    const handleZigPaymentType = () => {
      return tokenWithInformation.card_data.type === "CREDIT"
        ? "Cartão"
        : "Débito";
    };

    if (!isLogged) {
      setLoadingPayment(false)
      errorMessage(t("Checkout.userNotLoggedIn"));
    }

    if (isZig() && handleZigPaymentType() !== payment.payment_type) {
      errorMessage(t("Checkout.paymentTypeMismatched"));
      setLoadingPayment(false)
      return;
    }

    pushGAEvent("add_payment_info", {
      currency: "BRL",
      value: orderInfo.value,
      coupon: data.discount_code,
      payment_type: payment.payment_type,
      items: orderInfo.tickets.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        price: (item.value + item.fee).toFixed(2),
        quantity: 1,
      })),
    });

    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "Add payment Info",
            attributes: {
              currency: "BRL",
              value: orderInfo.value,
              coupon: data.discount_code,
              payment_type: payment.payment_type,
              items: orderInfo.tickets.map((item) => ({
                item_id: item.id,
                item_name: item.name,
                price: (item.value + item.fee).toFixed(2),
                quantity: 1,
              })),
            },
            platform: "web",
          },
        ],
      });
    }

    pushDataLayerAndEvent({
      currency: "BRL",
      value: orderInfo.value,
      payment_type: payment.payment_type,
      items: orderInfo.tickets,
      transaction_id: data.token,
      tax: orderInfo.tickets.reduce((acc, value) => acc + value.fee, 0),
      tkt_event_name: event.name,
      event_local: event.event_location.name,
      event_produced_by: event.producer.name,
      coupon: data.discount_code,
    });

    const address = {
      city: payment.city,
      state: payment.state,
      street: payment.street,
      number: payment.number,
      zip_code: payment.zip_code,
      complement: payment.complement,
      neighborhood: payment.neighborhood,
    };

    const attendeeInfos = [...attendees].map((item) => {
      (item.answers || []).forEach((answer) => {
        try {
          answer.answer = sift(JSON.parse(answer.answer));
        } catch {
          answer.answer = sift([answer.answer]);
        }
      });

      return item;
    });

    const object = {
      token: token,
      phone: MaskHelper.numberMask(payment.phone),
      address,
      event_id: event.id,
      attendees: attendeeInfos,
      document: MaskHelper.numberMask(payment.document),
      seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
      installments: payment.installments,
      payment_method: isZig() ? handleZigPaymentType() : payment.payment_type,
      card_data: {},
    };

    if (isZig()) {
      object.redirect_url = `${window.location.protocol}//${window.location.host}/processing/${token}`;
      object.checkout_session = checkoutSession;
      object.nonce = oneTimeToken;

      if (CARD_METHODS.includes(payment.payment_type)) {
        if (tokenWithInformation.customer.phone) {
          object.phone =
            tokenWithInformation.customer.phone.country_code +
            tokenWithInformation.customer.phone.number;
        }

        object.document =
          tokenWithInformation.customer.document.document_number;
        object.card_data = {
          id: tokenWithInformation.token,
          holder: tokenWithInformation.card_data.holder_name,
          brand: tokenWithInformation.card_data.brand,
          bin: tokenWithInformation.card_data.iin.slice(0, -2),
        };
      }
    } else if (CARD_METHODS.includes(payment.payment_type)) {
      try {
        if (data.acquirer === "pagseguro") {
          const service = new PagseguroService();

          const threeDSMethod =
            payment.payment_type === "Cartão" ? "CREDIT_CARD" : "DEBIT_CARD";

          const cardToken = await service.cardTokenizer(
            payment.card_number,
            payment.card_holder,
            payment.card_cvv,
            payment.card_expiration
          );
          object.card_data = { id: cardToken, holder: payment.card_holder };

          let enableThreeds = false;
          let forceThreedsAuthentication = false;

          if (threeDSMethod === "CREDIT_CARD") {
            const { data } = await checkoutApi.post("/antifraud/verify-user", {
              token,
              seller_name: object.seller_name,
            });

            enableThreeds = data.force_threeds;
            forceThreedsAuthentication = data.authenticated_threeds;
          }

          if (threeDSMethod === "DEBIT_CARD" || enableThreeds) {
            const threedsResult = await callThreedsService(
              threeDSMethod,
              cardToken,
              user,
              forceThreedsAuthentication,
              payment.installments,
              payment.phone,
              address
            );

            if (!threedsResult.success) {
              setIsSubmitting(false);
              setLoadingPayment(false);

              return;
            }

            object.card_data.threeds = {
              session: threedsResult.session,
              three_secure_id: threedsResult.id,
              three_secure_auth:
                threedsResult.authenticationStatus === "AUTHENTICATED",
            };
          }
        } else if (data.acquirer === "pagarme") {
          const service = new PagarMeService();
          const expirationInfos = payment.card_expiration.split("/");
          const expiration = expirationInfos[0] + expirationInfos[1];

          const cardToken = await service.cardTokenizer(
            payment.card_number,
            payment.card_holder,
            payment.card_cvv,
            expiration
          );

          object.card_data = { id: cardToken, holder: payment.card_holder };
        }
      } catch (err) {
        sendToLogger(err);
        setLoadingPayment(false);
        setIsSubmitting(false);
        errorMessage(gatewayErrorMessagesByType("card_error"));

        return;
      }
    }

    setInitializingYuno(false);
    checkoutApi
      .post(`/pay`, object, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then(async ({ data }) => {
        await trackingEventToPixel(
          "Purchase",
          { value: orderInfo.value, currency: "BRL" },
          0
        );

        triggerEventGA4AndADS(orderInfo.value);

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user.id,
            actions: [
              {
                action: "Purchase",
                attributes: {
                  tax: orderInfo.tickets.reduce(
                    (acc, value) => acc + value.fee,
                    0
                  ),
                  value: orderInfo.value,
                  coupon: data.discount_code,
                  currency: "BRL",
                  transaction_id: data.token,
                  items: orderInfo.tickets.map((item) => ({
                    item_id: item.id,
                    item_name: item.name,
                    price: (item.value + item.fee).toFixed(2),
                    quantity: 1,
                  })),
                },
                platform: "web",
              },
            ],
          });
        }

        sessionStorage.setItem("tickets", JSON.stringify(data));
        sessionStorage.setItem("order_token", token);
        sessionStorage.removeItem("seatsio");

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user.id,
            actions: [
              {
                action: "End order checkout",
                platform: "web",
              },
            ],
          });
        }
        sessionStorage.removeItem("newCheckoutSession");

        if (isZig() && orderInfo.value > 0) {
          const status = await yuno.yunoPaymentResult(checkoutSession);

          await yuno.continuePayment();

          if (!data.is_3ds) {
            if (status === "PENDING") {
              history.push(`/status/checkout/processing`);
            } else if (status === "SUCCEEDED") {
              history.push("/status/checkout/concluded");
            } else {
              history.push("/status/checkout/error");
            }
          }
        } else if (payment.payment_type === "PicPay") {
          sessionStorage.setItem("picpay-code", data.code);
          sessionStorage.setItem("type", "picpay");
          sessionStorage.setItem("picpay-url", data.url);
          history.push("/status/checkout/pending");
        } else if (payment.payment_type === "PIX") {
          sessionStorage.setItem("pix-code", data.code);
          sessionStorage.setItem("type", "pix");
          history.push("/status/checkout/pending");
        } else if (payment.payment_type === "Boleto") {
          sessionStorage.setItem("boleto", data.url);
          sessionStorage.setItem("type", "boleto");
          history.push("/status/checkout/pending");
        } else {
          if (data.status === "pré-autorizada") {
            history.push("/status/checkout/processing");
          } else if (data.status === "falhada") {
            history.push("/status/checkout/error");
          } else {
            history.push("/status/checkout/concluded");
          }
        }
      })
      .catch(async (err) => {
        sendToLogger(err);
        setIsSubmitting(false);
        await handlePaymentErrors(err);
        setLoadingPayment(false);
      })
      .finally(() => {
        setLoadingPayment(false);
        setInitializingYuno(false);
      });
  }

  async function callThreedsService(
    paymentMethod,
    cardToken,
    user,
    forceAuthentication,
    installment,
    phone,
    address
  ) {
    let session;

    try {
      session = await pagseguroService.buildSession(event.id);
    } catch (err) {
      sendToLogger(err);
      setLoadingPayment(false);
      setIsSubmitting(false);
      errorMessage(gatewayErrorMessagesByType("service_request_timeout"));

      return { success: false };
    }

    try {
      let requiredChallenge = false;

      const { id, status, authenticationStatus } =
        await pagseguroService.threeDSecure(
          {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone,
          },
          address,
          {
            type: paymentMethod,
            amount:
              (installment > 1 || paymentMethod === "CREDIT_CARD"
                ? installments[installment - 1].total.toFixed(2)
                : orderData.value) * 100,
            cardToken,
            installments: installment,
          },
          () => {
            requiredChallenge = true;
          }
        );

      if (authenticationStatus !== "AUTHENTICATED" && requiredChallenge) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_challenge_failure"));
        return { success: false };
      }

      if (paymentMethod === "DEBIT_CARD" && status === "AUTH_NOT_SUPPORTED") {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_debit_failure"));
        return { success: false };
      }

      if (
        status === "CHANGE_PAYMENT_METHOD" ||
        status === "AUTH_NOT_SUPPORTED"
      ) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_change_failure"));
        return { success: false };
      }

      if (
        paymentMethod === "CREDIT_CARD" &&
        forceAuthentication &&
        authenticationStatus !== "AUTHENTICATED"
      ) {
        setIsSubmitting(false);
        setLoadingPayment(false);
        errorMessage(gatewayErrorMessagesByType("3ds_failure"));
        return { success: false };
      }

      return { success: true, id, session, authenticationStatus };
    } catch (err) {
      sendToLogger(err);
      setIsSubmitting(false);
      setLoadingPayment(false);
      errorMessage(gatewayErrorMessagesByType("3ds_failure"));
    }

    return { success: false };
  }

  function getIcon(method) {
    return paymentIcons[method] || "";
  }

  function errorMessage(message, onDidClose) {
    Swal.fire({
      icon: "error",
      title: t("CommonExpressions.ops"),
      text: message,
      didClose: onDidClose,
    });
  }

  async function handlePaymentErrors(error) {
    if (!error?.response?.data) {
      errorMessage(t("Checkout.purchaseFailure"));
      return;
    }
    const errorData = error.response.data;

    if (errorData.retry) {
      if (errorData.max_attempts_reached) {
        Swal.fire({
          icon: "error",
          title: t("CommonExpressions.ops"),
          html: `${errorData.message}<br/><br/>${t(
            "Checkout.maxAttemptsReached"
          )}<br/><br/>${t("Checkout.maxAttemptsReachedMessage")}`,
          didClose: async () => {
            setValue("payment.payment_type", data.methods[0].method);
            window.location.reload();
          },
        });
      } else {
        if (isZig()) {
          await yunoErrorHandler();
        }
        errorMessage(errorData.message);
      }
    } else if (errorData.code === "expired_order") {
      finalizeCardByTimeOut();
    } else if (errorData.code === "not_found") {
      Swal.fire({
        icon: "error",
        title: t("CommonExpressions.ops"),
        text: t("Checkout.errorRedirect"),
        didClose: () => {
          history.push(`/eventos/${localStorage.getItem("slug")}`);
        },
      });
    } else {
      localStorage.setItem("error-message", errorData.message);
      localStorage.setItem("order-error", token);
      history.push("/checkout-error");
    }
  }

  function handleEditAttendee(e, item) {
    e.preventDefault();

    item.automatic_fill = !item.automatic_fill;

    if (!!item.passport_name) {
      item.email = "";
      item.last_name = "";
      item.first_name = "";
      item.use_my_data = false;
    }

    clearErrors("attendees");
    setValue("attendees", [...watch("attendees")]);
  }

  function handleUseMyData(event, item) {
    if (event.target.checked) {
      item.email = user.email;
      item.last_name = user.last_name;
      item.first_name = user.first_name;
      item.use_my_data = true;
    } else {
      item.email = "";
      item.last_name = "";
      item.first_name = "";
      item.use_my_data = false;
    }

    clearErrors("attendees");
    setValue("attendees", [...watch("attendees")]);
  }

  function handlePaymentUserFill(event) {
    if (event.target.checked) {
      setValue("payment.document", MaskHelper.cpfMask(user.document));
      setValue("payment.phone", MaskHelper.phoneMask(user.telephone));

      if (user.address?.length > 0) {
        setValue("payment.zip_code", user.address.zip_code);
        setValue("payment.street", user.address.street);
        setValue("payment.state", user.address.state);
        setValue("payment.neighborhood", user.address.neighborhood);
        setValue("payment.number", user.address.number);
        setValue("payment.city", user.address.city);
        setZipcodeFetched(true);
      }
    } else {
      setValue("payment.document", "");
      setValue("payment.phone", "");
      setValue("payment.zip_code", "");
      setValue("payment.street", "");
      setValue("payment.state", "");
      setValue("payment.neighborhood", "");
      setValue("payment.number", "");
      setValue("payment.city", "");
    }

    clearErrors("payment.document");
    clearErrors("payment.phone");
    clearErrors("payment.zip_code");
    clearErrors("payment.street");
    clearErrors("payment.state");
    clearErrors("payment.neighborhood");
    clearErrors("payment.number");
    clearErrors("payment.city");
  }

  function handleAnswer(event, eventForm, attendeeIndex, questionIndex) {
    const field = `attendees[${attendeeIndex}].answers[${questionIndex}].answer`;

    let value = event.target.value;
    let data = watch(field);

    if (eventForm.field_type.type === "checkbox") {
      data = JSON.parse(data || "[]");
      const index = data.findIndex((item) => item === value);

      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(value);
      }

      data = data.length ? JSON.stringify(data) : "";
    } else {
      data = value;
    }

    setValue(field, data);
  }

  function trackingEventToPixel(title, data, timeout = 3000) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (event?.fb_pixel_id) {
          ReactPixel.trackSingle(event.fb_pixel_id, title, data);
        }

        if (process.env.REACT_APP_PIXEL_ID) {
          ReactPixel.trackSingle(process.env.REACT_APP_PIXEL_ID, title, data);
        }

        resolve();
      }, timeout);
    });
  }

  function handlerPaymentTypeChange(paymentMethod) {
    setValue("payment.payment_type", paymentMethod.method);
    setValue("payment.payment_mode", paymentMethod.payment_mode);

    if (isZig() && CARD_METHODS.includes(paymentMethod.method)) {
      setInitializingYuno(true);
      mountYunoCheckout();
    }
  }

  async function formHandlerSubmit(formData) {
    if (
      isZig() &&
      !CARD_METHODS.includes(formData.payment.payment_type) &&
      orderData.value > 0
    ) {
      setInitializingYuno(true);
      mountYunoCheckout();
    } else {
      await sendData(formData);
    }
  }

  const createCheckoutSession = async (data) => {
    let orderInfo;
    if (watch("payment.payment_type")) {
      orderInfo = data.methods.find(
        ({ method }) => method === watch("payment.payment_type")
      );
      if (!orderInfo) {
        orderInfo = data.methods[0];
      }
    } else {
      orderInfo = data.methods[0];
    }

    return await checkoutApi
      .post("begin", {
        token: token,
        event_id: event.id,
        currency: "BRL",
        amount: orderInfo.value,
        seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
        redirect_url: `${window.location.protocol}//${window.location.host}/processing/${token}`,
      })
      .then(({ data }) => data)
      .catch((error) => {
        sendToLogger(error);
        setInitializingYuno(false);
        setIsYunoInitialized(false);
      });
  };

  async function initCheckoutLite() {
    setIsYunoInitialized(true);

    const { session, raw_data } = await createCheckoutSession(data);

    const availableMethods = compareYunoPaymentMethodsWithSystem(
      raw_data,
      data.methods
    );

    if (!availableMethods.length) {
      setOrderData(data.methods[0]);
      setData({ ...data, methods: data.methods });
      setInitializingYuno(false);
      return
    }
    const firstMethod = availableMethods[0];
    setOrderData(firstMethod);

    setData({ ...data, methods: availableMethods });
    if (firstMethod.value !== 0) {
      if (!session) {
        errorMessage(
          "Ocorreu um erro. Clique abaixo para ser redirecionado à página do evento e refazer a compra.",
          () => history.push(`/eventos/${localStorage.getItem("slug")}`)
        );
      } else {
        await yuno.startCheckout({
          checkoutSession: session,
          elementSelector: "#root-div",
          renderMode: {
            elementSelector: "#yuno-payment-form",
            type: "element",
          },
          card: {
            type: "extends",
            cardSaveEnable: false,
            styles: `@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
            [class*=Yuno] {
              font-family: 'Lato' !important;
            }
            [class*=Yuno-card-form__title] {
              display: none!important;
            }
            [class*=Yuno-card-icon-bar__content] {
              display: none!important;
            }
            [class*=Yuno-card-container].css-1sivy39  {
              width: 100%!important;
            }`,
          },
          automaticallyUnmount: false,
          countryCode: userData?.countryCode || "BR",
          language: i18n.language,
          keepLoader: false,
          showLoading: false,
          showPaymentStatus: false,
          onLoading: () => {},
          onRendered: () => {
            setInitializingYuno(false);
          },
          yunoCreatePayment: (oneTimeToken, tokenWithInformation) => {
            createPaymentYuno(oneTimeToken, tokenWithInformation, session);
          },
          yunoError: () => {
            yunoErrorHandler();
          },
        });
      }
    }
    setInitializingYuno(false);
  }

  const mountYunoCheckout = () => {
    yuno.mountCheckoutLite({
      paymentMethodType: yunoPaymentDictionary(watch("payment.payment_type")),
      vaultedToken: null,
    });

    yuno.startPayment();
  };

  async function createPaymentYuno(
    oneTimeToken,
    tokenWithInformation,
    checkoutSession
  ) {
    const isValid = await trigger(undefined, { shouldFocus: true });

    if (isValid) {
      await sendData(
        getValues(),
        oneTimeToken,
        tokenWithInformation,
        checkoutSession
      );
    }
  }

  const yunoErrorHandler = async () => {
    const { session } = await createCheckoutSession(data);
    sessionStorage.setItem("newCheckoutSession", session);

    await yuno.updateCheckoutSession(session);

    await yuno.notifyError();
  };

  const yunoPaymentDictionary = (method) => {
    return yunoPaymentTypes[method];
  };

  const compareYunoPaymentMethodsWithSystem = (
    yunoPaymentMethods,
    systemMethods
  ) => {
    const yunoAvailabelMethods = [
      ...new Set(yunoPaymentMethods?.data.providerData.map(({ type }) => type)),
    ];

    if (!yunoAvailabelMethods) {
      return systemMethods;
    }

    return systemMethods
      .map((paymentMethod) => {
        if (
          paymentMethod.payment_mode === "zig" ||
          (!paymentMethod.payment_mode && isZig())
        ) {
          if (
            paymentMethod.method === "Google Pay" &&
            yunoAvailabelMethods.includes("GOOGLE_PAY")
          ) {
            return paymentMethod;
          } else if (
            paymentMethod.method === "Apple Pay" &&
            yunoAvailabelMethods.includes("APPLE_PAY")
          ) {
          } else if (
            CARD_METHODS.includes(paymentMethod.method) &&
            yunoAvailabelMethods.includes("CARD")
          ) {
            return paymentMethod;
          }
        } else {
          return paymentMethod;
        }
        return null;
      })
      .filter((paymentMethod) => paymentMethod !== null);
  };

  if (!loadingPage && !data) {
    return null;
  }

  return (
    <>
      {
        window.location.pathname.includes('checkout') && <CompleteUserRegistration />
      }

      <Fragment>
        {loadingPage ? (
          <Fragment>
            <div className="loading-container"></div>
            <Loader></Loader>
          </Fragment>
        ) : (
          <Fragment>
            <MetaTags>
              {event.fb_domain_verification_code && (
                <meta
                  name="facebook-domain-verification"
                  content={event.fb_domain_verification_code}
                />
              )}
            </MetaTags>

            {loadingPayment && (
              <Fragment>
                <Loader
                  title={t("Checkout.wait")}
                  text={t("Checkout.processing")}
                />
              </Fragment>
            )}

            {initializingYuno && (
              <Fragment>
                <Loader
                  title={t("Checkout.wait")}
                  text={t("Checkout.processing")}
                />
              </Fragment>
            )}

            <CountDownView
              expiresIn={data.expires}
              secondsToExpires={data.time_to_expire}
              onTimesUp={finalizeCardByTimeOut}
            />

            <div
              id="page-checkout"
              className="container my-4"
              style={{ marginBottom: isInZigApp() ? "4.2rem" : "" }}
            >
              <Row>
                <Col id="checkout-title">
                  <h2 className="event-title">{event.name}</h2>
                  <div id="checkout-eventinfos">
                    <p className="mr-2 mb-0">
                      {t("CommonExpressions.orderNumber")}{" "}
                      <strong>{token}</strong>
                    </p>
                  </div>
                </Col>
              </Row>
              {showInformativeBox && (
                <div
                  className={`discount-description ${
                    isUserElegibleForDiscount ? "success" : "error"
                  }`}
                  onClick={handleToggleExpand}
                >
                  <div className="discount-title">
                    <img
                      src={isUserElegibleForDiscount ? success : warning}
                      alt="alert box icon"
                      id="alert-box-icon"
                    />
                    <p>
                      <strong>
                        {isUserElegibleForDiscount ? (
                          <>
                            {t("Checkout.appliedDiscount")} {discountCoupon}.
                          </>
                        ) : (
                          <>
                            {t("Checkout.coupon")} {discountCoupon}{" "}
                            {t("Checkout.notEligibleDiscount")}
                          </>
                        )}
                      </strong>
                    </p>
                    {!isUserElegibleForDiscount &&
                      integrationCouponDescription && (
                        <img
                          src={arrow}
                          alt="arrow icon"
                          id="arrow-icon"
                          className={isExpanded ? "rotated-arrow" : ""}
                        />
                      )}
                  </div>
                  <div>
                    {!isUserElegibleForDiscount &&
                      isExpanded &&
                      integrationCouponDescription && (
                        <p id="coupon-description">
                          {integrationCouponDescription}
                        </p>
                      )}
                  </div>
                </div>
              )}

              <form onSubmit={handleSubmit(formHandlerSubmit)}>
                <Row className="mt-4">
                  <Col id="checkout" lg={8}>
                    <div>
                      <Row>
                        <Col>
                          <Element name="attendees" className="card">
                            <div className="card-header">
                              {t("Checkout.participantInformation")}
                            </div>
                            <div className="card-body">
                              {watch("attendees").map((item, idx) => (
                                <>
                                  <Row className="ticket-detail" key={idx}>
                                    <Col xs={12}>
                                      <div className="card-title">
                                        {item.passport_name ? (
                                          <>
                                            {item.passport_name} (
                                            {item.passport_index}):{" "}
                                            {ticketNomenclatureInSingular(
                                              event.ticket_nomenclature
                                            )}{" "}
                                            {idx + 1} - {item.ticket}
                                            {item.seat_name
                                              ? ` - ${item.seat_name}`
                                              : ""}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {ticketNomenclatureInSingular(
                                              event.ticket_nomenclature
                                            )}{" "}
                                            {idx + 1} - {item.ticket}
                                            {item.seat_name
                                              ? ` - ${item.seat_name}`
                                              : ""}
                                          </>
                                        )}
                                      </div>
                                    </Col>

                                    {!!event.enable_attendees_checkout_autofill && (
                                      <Col
                                        xs={12}
                                        className={
                                          item.automatic_fill
                                            ? "d-none"
                                            : "d-block"
                                        }
                                      >
                                        <Form.Group>
                                          <Form.Check
                                            id={"autofill-" + idx}
                                            label={t("Checkout.fillWithMyData")}
                                            inline={true}
                                            className="input-autofill"
                                            checked={item.use_my_data}
                                            onChange={(e) =>
                                              handleUseMyData(e, item)
                                            }
                                          />
                                        </Form.Group>
                                      </Col>
                                    )}
                                    <Col
                                      xs={12}
                                      className={
                                        item.automatic_fill
                                          ? "d-block"
                                          : "d-none"
                                      }
                                    >
                                      <Form.Group>
                                        <div className="prefillContainer">
                                          <div className="prefillInfoContainer">
                                            <span className="prefillAttendeeName">
                                              {`${item.first_name} ${item.last_name}`}
                                            </span>
                                            <span className="prefillAttendeeEmail">
                                              {item.email}
                                            </span>
                                          </div>
                                          <button
                                            className="prefillEditButton"
                                            onClick={(e) =>
                                              handleEditAttendee(e, item)
                                            }
                                          >
                                            {t("Checkout.edit")}
                                          </button>
                                        </div>
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      xs={6}
                                      className={
                                        item.automatic_fill
                                          ? "d-none"
                                          : "d-block"
                                      }
                                    >
                                      <Form.Group>
                                        <Form.Label
                                          htmlFor={"first_name-" + idx}
                                        >
                                          <span className="text-danger">*</span>
                                          {t("CommonExpressions.name")}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          id={"first_name-" + idx}
                                          className={
                                            errors.attendees?.[idx]
                                              ?.first_name && "is-invalid"
                                          }
                                          {...register(
                                            `attendees.[${idx}].first_name`
                                          )}
                                        />
                                        {errors.attendees?.[idx]
                                          ?.first_name && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.attendees?.[idx]
                                                ?.first_name?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      xs={6}
                                      className={
                                        item.automatic_fill
                                          ? "d-none"
                                          : "d-block"
                                      }
                                    >
                                      <Form.Group>
                                        <Form.Label
                                          htmlFor={"last_name-" + idx}
                                        >
                                          <span className="text-danger">*</span>
                                          {t("CommonExpressions.lastname")}
                                        </Form.Label>
                                        <Form.Control
                                          id={"last_name-" + idx}
                                          type="text"
                                          className={
                                            errors.attendees?.[idx]
                                              ?.last_name && "is-invalid"
                                          }
                                          {...register(
                                            `attendees[${idx}].last_name`
                                          )}
                                        />
                                        {errors.attendees?.[idx]?.last_name && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.attendees?.[idx]?.last_name
                                                ?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      className={`mt-2 ${
                                        item.automatic_fill
                                          ? "d-none"
                                          : "d-block"
                                      }`}
                                    >
                                      <Form.Group>
                                        <Form.Label htmlFor={"email-" + idx}>
                                          <span className="text-danger">*</span>
                                          {t("Checkout.email")}
                                        </Form.Label>
                                        <Form.Control
                                          id={"email-" + idx}
                                          type="email"
                                          className={
                                            errors.attendees?.[idx]?.email &&
                                            "is-invalid"
                                          }
                                          {...register(
                                            `attendees[${idx}].email`
                                          )}
                                        />
                                        {errors.attendees?.[idx]?.email && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.attendees?.[idx]?.email
                                                ?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>

                                    {formQuestions.map((field, index) => (
                                      <AttendeeQuestionView
                                        id={`q-${index}-${idx}`}
                                        name={`attendees[${idx}].answers[${index}].answer`}
                                        field={field}
                                        value={watch(
                                          `attendees[${idx}].answers[${index}].answer`
                                        )}
                                        errors={
                                          errors.attendees?.[idx]?.answers?.[
                                            index
                                          ]?.answer
                                        }
                                        register={register}
                                        onChange={(ev) =>
                                          handleAnswer(ev, field, idx, index)
                                        }
                                      />
                                    ))}
                                  </Row>
                                </>
                              ))}
                            </div>
                          </Element>
                        </Col>
                      </Row>
                    </div>

                    <Row className="payment-row">
                      <Col>
                        <Element name="paymentInfos" className="card">
                          <div className="card-header">
                            <div className="d-inline-block" id="payment-info">
                              {orderData?.value > 0
                                ? t("Checkout.paymentInformation")
                                : t("Checkout.buyerInformation")}
                            </div>
                          </div>
                          <div className="card-body">
                            <fieldset
                              className={
                                orderData?.value > 0 ? "d-block" : "d-none"
                              }
                            >
                              <div role="group">
                                <Row
                                  role="radiogroup"
                                  className="d-flex flex-direction-row"
                                >
                                  {data.methods?.map((paymentMethod, idx) => (
                                    <Col
                                      xs={12}
                                      md={6}
                                      key={idx}
                                      onClick={() =>
                                        handlerPaymentTypeChange(paymentMethod)
                                      }
                                      className={
                                        paymentMethod.method === "Apple Pay" &&
                                        !["macOS", "iOS"].includes(os)
                                          ? "d-none"
                                          : ""
                                      }
                                    >
                                      <Form.Group>
                                        <div className="btn custom-radio">
                                          <Form.Check
                                            id={"payment_type" + idx}
                                            inline={true}
                                            type="radio"
                                            value={paymentMethod.method}
                                            checked={
                                              watch("payment.payment_type") ===
                                              paymentMethod.method
                                            }
                                            {...register(
                                              "payment.payment_type"
                                            )}
                                          />
                                          <Form.Label className="form-check-label">
                                            {paymentMethod.method ===
                                              "Cartão" && (
                                              <>{t("Checkout.creditCard")}</>
                                            )}
                                            {paymentMethod.method ===
                                              "Débito" && (
                                              <>{t("Checkout.debitCard")}</>
                                            )}
                                            {!["Cartão", "Débito"].includes(
                                              paymentMethod.method
                                            ) && <>{paymentMethod.method}</>}
                                          </Form.Label>

                                          {!!getIcon(paymentMethod.method) && (
                                            <img
                                              src={getIcon(
                                                paymentMethod.method
                                              )}
                                              alt="payment icon"
                                            />
                                          )}
                                        </div>
                                      </Form.Group>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </fieldset>

                            <Row
                              className={
                                watch("payment.payment_type") === "Cartão"
                                  ? "d-block"
                                  : "d-none"
                              }
                            >
                              <Col className="mb-2">
                                <Form.Group>
                                  <Form.Label htmlFor="installments">
                                    <span className="text-danger">*</span>
                                    {t("Checkout.installments")}{" "}
                                    {installments.length}x)
                                  </Form.Label>
                                  <Form.Control
                                    id="installments"
                                    as="select"
                                    className="form-control"
                                    {...register("payment.installments")}
                                  >
                                    {installments.map((isntallment, index) => (
                                      <option key={index} value={index + 1}>
                                        {index + 1}x de{" "}
                                        {MaskHelper.numberToLocaleCurrency(
                                          isntallment.installment
                                        )}{" "}
                                        ={" "}
                                        {MaskHelper.numberToLocaleCurrency(
                                          isntallment.total
                                        )}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {errors?.payment?.installments && (
                                    <Form.Text className="error-message">
                                      {errors.payment?.installments?.message}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>

                            {(!isZig() || orderData.value === 0) && (
                              <>
                                <div
                                  className={
                                    CARD_METHODS.includes(
                                      watch("payment.payment_type")
                                    )
                                      ? "d-block"
                                      : "d-none"
                                  }
                                >
                                  <Row>
                                    <Col md={6} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="card_number">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.cardNumber")}
                                        </Form.Label>
                                        <Form.Control
                                          id="card_number"
                                          type="text"
                                          className={
                                            errors.payment?.card_number &&
                                            "is-invalid"
                                          }
                                          placeholder="0000 0000 0000 0000"
                                          {...register("payment.card_number")}
                                          onChange={(e) =>
                                            setValue(
                                              "payment.card_number",
                                              MaskHelper.commonCardMask(
                                                e.target.value
                                              )
                                            )
                                          }
                                        />

                                        {cardType && (
                                          <div className="card-brand">
                                            <img
                                              src={`/${cardType}.svg`}
                                              alt={cardType}
                                            />
                                          </div>
                                        )}

                                        {errors.payment?.card_number && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.payment?.card_number
                                                ?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6} md={3} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="card_expiration">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.expirationDate")}
                                        </Form.Label>
                                        <Form.Control
                                          id="card_expiration"
                                          type="text"
                                          className={
                                            errors.payment?.card_expiration &&
                                            "is-invalid"
                                          }
                                          placeholder="MM/AA"
                                          {...register(
                                            "payment.card_expiration"
                                          )}
                                          onChange={(e) =>
                                            setValue(
                                              "payment.card_expiration",
                                              MaskHelper.cardExpirationMask(
                                                e.target.value
                                              )
                                            )
                                          }
                                        />
                                        {errors.payment?.card_expiration && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.payment?.card_expiration
                                                ?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col xs={6} md={3} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="card_cvv">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.securityCode")}
                                        </Form.Label>
                                        <Form.Control
                                          id="card_cvv"
                                          name="card_cvv"
                                          type="text"
                                          className={
                                            errors.payment?.card_cvv &&
                                            "is-invalid"
                                          }
                                          placeholder={list(
                                            0,
                                            cardCvv - 1,
                                            "0"
                                          ).join("")}
                                          {...register("payment.card_cvv")}
                                          onChange={(e) =>
                                            setValue(
                                              "payment.card_cvv",
                                              MaskHelper.numberMask(
                                                e.target.value
                                              ).replace(
                                                new RegExp(
                                                  "(\\d{" +
                                                    cardCvv +
                                                    "})\\d+?$",
                                                  "gi"
                                                ),
                                                "$1"
                                              )
                                            )
                                          }
                                        />
                                        {errors.payment?.card_cvv && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.card_cvv?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <hr />

                                  <Row>
                                    <Col>
                                      <p className="checkout-subtitle">
                                        {t("Checkout.cardholderData")}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Check
                                        type="checkbox"
                                        label={t("Checkout.fillWithMyData")}
                                        className="input-autofill"
                                        inline
                                        id="payment_autofill"
                                        onChange={handlePaymentUserFill}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row
                                  className={
                                    CARD_METHODS.includes(
                                      watch("payment.payment_type")
                                    )
                                      ? "d-block"
                                      : "d-none"
                                  }
                                >
                                  <Col className="mb-2">
                                    <Form.Group>
                                      <Form.Label htmlFor="card_holder">
                                        <span className="text-danger">*</span>
                                        {t("Checkout.printedNameOnCard")}
                                      </Form.Label>
                                      <Form.Control
                                        id="card_holder"
                                        type="text"
                                        className={
                                          errors.payment?.card_holder &&
                                          "is-invalid"
                                        }
                                        {...register("payment.card_holder")}
                                        onChange={(e) =>
                                          setValue(
                                            "payment.card_holder",
                                            e.target.value.replace(
                                              /[^a-zA-Z ]/gi,
                                              ""
                                            )
                                          )
                                        }
                                      />
                                      {errors.payment?.card_holder && (
                                        <Form.Text className="error-message">
                                          {errors.payment?.card_holder?.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={6} className="mb-2">
                                    <Form.Group>
                                      <Form.Label htmlFor="document">
                                        <span className="text-danger">*</span>{" "}
                                        {t("Checkout.twoDocuments")}
                                      </Form.Label>
                                      <Form.Control
                                        id="document"
                                        type="text"
                                        className={
                                          errors.payment?.document &&
                                          "is-invalid"
                                        }
                                        {...register("payment.document")}
                                        onChange={(e) =>
                                          setValue(
                                            "payment.document",
                                            MaskHelper.brazilianDocumentMask(
                                              e.target.value
                                            )
                                          )
                                        }
                                      />
                                      {errors.payment?.document && (
                                        <Form.Text className="error-message">
                                          {errors.payment?.document?.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6} className="mb-2">
                                    <Form.Group>
                                      <Form.Label htmlFor="phone">
                                        <span className="text-danger">*</span>
                                        {t("Checkout.phone")}
                                      </Form.Label>
                                      <Form.Control
                                        id="phone"
                                        type="text"
                                        className={
                                          errors.payment?.phone && "is-invalid"
                                        }
                                        {...register("payment.phone")}
                                        onChange={(e) =>
                                          setValue(
                                            "payment.phone",
                                            MaskHelper.phoneMask(e.target.value)
                                          )
                                        }
                                      />
                                      {errors.payment?.phone && (
                                        <Form.Text className="error-message">
                                          {errors.payment?.phone?.message}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <div
                                  className={
                                    !ADDRESS_METHODS.includes(
                                      watch("payment.payment_type")
                                    )
                                      ? "d-block"
                                      : "d-none"
                                  }
                                >
                                  <hr />

                                  <Row>
                                    <Col>
                                      <p className="checkout-subtitle">
                                        {t("Checkout.address")}
                                      </p>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={7} md={4} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="zip_code">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.zipcode")}
                                        </Form.Label>
                                        <Form.Control
                                          id="zip_code"
                                          type="text"
                                          className={
                                            errors.payment?.zip_code &&
                                            "is-invalid"
                                          }
                                          {...register("payment.zip_code")}
                                          onChange={(e) =>
                                            setValue(
                                              "payment.zip_code",
                                              MaskHelper.zipcodeMask(
                                                e.target.value
                                              )
                                            )
                                          }
                                        />
                                        {errors.payment?.zip_code && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.zip_code?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    {fetchingZipcode ? (
                                      <Col className="d-flex align-items-end">
                                        <span className="pb-4">
                                          {t("Checkout.searchingZipcode")}
                                        </span>
                                      </Col>
                                    ) : (
                                      <Col xs={5} className="postal-code mb-2">
                                        <a
                                          rel="noopener noreferrer"
                                          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                          target="_blank"
                                          onClick={() =>
                                            setZipcodeFetched(true)
                                          }
                                        >
                                          {t("Checkout.unknownZipcode")}
                                        </a>
                                      </Col>
                                    )}
                                  </Row>
                                  <Row
                                    className={
                                      zipcodeFetched ? "d-flex" : "d-none"
                                    }
                                  >
                                    <Col md={7} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="address">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.address")}
                                        </Form.Label>
                                        <Form.Control
                                          id="street"
                                          type="text"
                                          className={
                                            errors.payment?.street &&
                                            "is-invalid"
                                          }
                                          {...register("payment.street")}
                                          onChange={({ target }) =>
                                            setValue(
                                              "payment.street",
                                              target.value
                                            )
                                          }
                                        />
                                        {errors.payment?.street && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.street?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md={5} className="mb-2">
                                      <Form.Group>
                                        <Form.Label>
                                          <span className="text-danger">*</span>
                                          {t("Checkout.number")}
                                        </Form.Label>
                                        <Form.Control
                                          id="number"
                                          type="text"
                                          className={
                                            errors.payment?.number &&
                                            "is-invalid"
                                          }
                                          {...register("payment.number")}
                                          onChange={({ target }) =>
                                            setValue(
                                              "payment.number",
                                              target.value.replace(/\D/g, "")
                                            )
                                          }
                                        />
                                        {errors.payment?.number && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.number?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="neighborhood">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.neighborhood")}
                                        </Form.Label>
                                        <Form.Control
                                          id="neighborhood"
                                          type="text"
                                          className={
                                            errors.payment?.neighborhood &&
                                            "is-invalid"
                                          }
                                          {...register("payment.neighborhood")}
                                          onChange={({ target }) =>
                                            setValue(
                                              "payment.neighborhood",
                                              target.value
                                            )
                                          }
                                        />
                                        {errors.payment?.neighborhood && (
                                          <Form.Text className="error-message">
                                            {
                                              errors.payment?.neighborhood
                                                ?.message
                                            }
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                      <Form.Group>
                                        <Form.Label htmlFor="city">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.city")}
                                        </Form.Label>
                                        <Form.Control
                                          id="city"
                                          name="city"
                                          type="text"
                                          className={
                                            errors.payment?.city && "is-invalid"
                                          }
                                          {...register("payment.city")}
                                          onChange={({ target }) =>
                                            setValue(
                                              "payment.city",
                                              target.value
                                            )
                                          }
                                        />
                                        {errors.payment?.city && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.city?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                    <Col md={4} className="mb-2">
                                      <Form.Group htmlFor="state">
                                        <Form.Label htmlFor="state">
                                          <span className="text-danger">*</span>
                                          {t("Checkout.state")}
                                        </Form.Label>
                                        <Form.Control
                                          id="state"
                                          as="select"
                                          className={
                                            errors.payment?.state &&
                                            "is-invalid"
                                          }
                                          {...register("payment.state")}
                                          onChange={({ target }) =>
                                            setValue(
                                              "payment.state",
                                              target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            {t("Checkout.selectState")}
                                          </option>
                                          {Object.keys(STATE_NAMES).map(
                                            (abbr, idx) => (
                                              <option
                                                id={abbr}
                                                key={idx}
                                                value={abbr}
                                              >
                                                {STATE_NAMES[abbr]}
                                              </option>
                                            )
                                          )}
                                        </Form.Control>
                                        {errors.payment?.state && (
                                          <Form.Text className="error-message">
                                            {errors.payment?.state?.message}
                                          </Form.Text>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )}
                          </div>
                        </Element>
                      </Col>
                    </Row>

                    <div
                      className={
                        isZig() &&
                        orderData.value > 0 &&
                        !WALLET_METHODS.includes(watch("payment.payment_type"))
                          ? "d-block"
                          : "d-none"
                      }
                    >
                      <Row>
                        <Col>
                          <Element name="billingAddressInfos" className="card">
                            <div className="card-header">
                              <div
                                className="d-inline-block"
                                id="billing-address-info"
                              >
                                {t("Checkout.billingAddress")}
                              </div>
                            </div>
                            <div className="card-body">
                              <div>
                                <Row>
                                  <Col>
                                    {
                                      <>
                                        <Row>
                                          <Col xs={9} md={6} className="mb-2">
                                            <Form.Group>
                                              <Form.Label htmlFor="zip_code">
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.zipcode")}
                                              </Form.Label>
                                              <Form.Control
                                                id="zip_code"
                                                type="text"
                                                className={
                                                  errors.payment?.zip_code &&
                                                  "is-invalid"
                                                }
                                                {...register(
                                                  "payment.zip_code"
                                                )}
                                                onChange={(e) =>
                                                  setValue(
                                                    "payment.zip_code",
                                                    MaskHelper.zipcodeMask(
                                                      e.target.value
                                                    )
                                                  )
                                                }
                                              />
                                              {errors.payment?.zip_code && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.zip_code
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                          {fetchingZipcode ? (
                                            <Col className="d-flex align-items-end">
                                              <span className="pb-4">
                                                {t("Checkout.searchingZipcode")}
                                              </span>
                                            </Col>
                                          ) : (
                                            <Col
                                              xs={3}
                                              className="postal-code mb-2"
                                            >
                                              <a
                                                rel="noopener noreferrer"
                                                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                                target="_blank"
                                                onClick={() =>
                                                  setZipcodeFetched(true)
                                                }
                                              >
                                                {t("Checkout.unknownZipcode")}
                                              </a>
                                            </Col>
                                          )}
                                        </Row>

                                        <Row
                                          className={
                                            zipcodeFetched ? "d-flex" : "d-none"
                                          }
                                        >
                                          <Col md={8} className="mb-2">
                                            <Form.Group>
                                              <Form.Label htmlFor="address">
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.address")}
                                              </Form.Label>
                                              <Form.Control
                                                id="street"
                                                type="text"
                                                className={
                                                  errors.payment?.street &&
                                                  "is-invalid"
                                                }
                                                {...register("payment.street")}
                                                onChange={({ target }) =>
                                                  setValue(
                                                    "payment.street",
                                                    target.value
                                                  )
                                                }
                                              />
                                              {errors.payment?.street && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.street
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                          <Col md={4} className="mb-2">
                                            <Form.Group>
                                              <Form.Label>
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.number")}
                                              </Form.Label>
                                              <Form.Control
                                                id="number"
                                                type="text"
                                                className={
                                                  errors.payment?.number &&
                                                  "is-invalid"
                                                }
                                                {...register("payment.number")}
                                              />
                                              {errors.payment?.number && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.number
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                          <Col md={4} className="mb-2">
                                            <Form.Group>
                                              <Form.Label htmlFor="neighborhood">
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.neighborhood")}
                                              </Form.Label>
                                              <Form.Control
                                                id="neighborhood"
                                                type="text"
                                                className={
                                                  errors.payment
                                                    ?.neighborhood &&
                                                  "is-invalid"
                                                }
                                                {...register(
                                                  "payment.neighborhood"
                                                )}
                                                onChange={({ target }) =>
                                                  setValue(
                                                    "payment.neighborhood",
                                                    target.value
                                                  )
                                                }
                                              />
                                              {errors.payment?.neighborhood && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.neighborhood
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                          <Col md={4} className="mb-2">
                                            <Form.Group>
                                              <Form.Label htmlFor="city">
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.city")}
                                              </Form.Label>
                                              <Form.Control
                                                id="city"
                                                name="city"
                                                type="text"
                                                className={
                                                  errors.payment?.city &&
                                                  "is-invalid"
                                                }
                                                {...register("payment.city")}
                                                onChange={({ target }) =>
                                                  setValue(
                                                    "payment.city",
                                                    target.value
                                                  )
                                                }
                                              />
                                              {errors.payment?.city && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.city
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                          <Col md={4} className="mb-2">
                                            <Form.Group htmlFor="state">
                                              <Form.Label htmlFor="state">
                                                <span className="text-danger">
                                                  *
                                                </span>
                                                {t("Checkout.state")}
                                              </Form.Label>
                                              <Form.Control
                                                id="state"
                                                as="select"
                                                className={
                                                  errors.payment?.state &&
                                                  "is-invalid"
                                                }
                                                {...register("payment.state")}
                                                onChange={({ target }) =>
                                                  setValue(
                                                    "payment.state",
                                                    target.value
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  {t("Checkout.selectState")}
                                                </option>
                                                {Object.keys(STATE_NAMES).map(
                                                  (abbr, idx) => (
                                                    <option
                                                      id={abbr}
                                                      key={idx}
                                                      value={abbr}
                                                    >
                                                      {STATE_NAMES[abbr]}
                                                    </option>
                                                  )
                                                )}
                                              </Form.Control>

                                              {errors.payment?.state && (
                                                <Form.Text className="error-message">
                                                  {
                                                    errors.payment?.state
                                                      ?.message
                                                  }
                                                </Form.Text>
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                      </>
                                    }
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Element>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Element name="cardPaymentInfos" className="card">
                            <div className="card-header">
                              <div
                                className="d-inline-block"
                                id="billing-address-info"
                              >
                                {t("Checkout.insertCardData")}
                              </div>
                            </div>
                            <div className="card-body">
                              <Col>
                                <div id="yuno-payment-form"></div>
                              </Col>

                              <Row className="safe-buy-and-terms">
                                <Col xs={12}>
                                  <div className="d-flex justify-content-center align-items-center safe-buy">
                                    <img
                                      src={shield_security}
                                      alt="shield_security"
                                    />
                                    <span>{t("Checkout.safePurchase")}</span>
                                  </div>
                                </Col>
                                <AcknownledgeTermsView />
                              </Row>
                            </div>
                          </Element>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col>
                        <OrderSummaryView
                          orderData={orderData}
                          discountCode={data.discount_code}
                          discountType={data.discount_type}
                          installments={watch("payment.installments")}
                          discountValue={data.discount_value}
                          paymentMethod={watch("payment.payment_type")}
                          installmentList={installments}
                        />
                      </Col>
                    </Row>
                    <div
                      className={
                        isZig() &&
                        CARD_METHODS.includes(watch("payment.payment_type"))
                          ? "d-none"
                          : "d-block"
                      }
                    >
                      <Row>
                        <Col>
                          <AcceptTermsView
                            checked={acceptTerms}
                            onChange={setAcceptTerms}
                          />
                        </Col>
                      </Row>

                      <Row className="justify-content-end my-4">
                        <Col md={12}>
                          <button
                            disabled={
                              !acceptTerms || fetchingZipcode || isSubmitting
                            }
                            className="btn order-submit-button btn-md w-100"
                            type="submit"
                          >
                            {t("Checkout.finishPurchase")}
                          </button>
                          <p className="safe-purchase">
                            {t("Checkout.safePurchase")}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  {!isInZigApp() && (
                    <Col lg={4} className="d-none d-lg-block">
                      <TicketsSummaryView orderData={orderData} />
                    </Col>
                  )}
                </Row>
              </form>
            </div>
          </Fragment>
        )}
      </Fragment>

      <div id="root-div"></div>
    </>
  );
};

export default CheckoutNew;
