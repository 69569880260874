import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./styles.css";
import { useTranslation } from "react-i18next";

export default function AcknownledgeTermsView() {
  const { t } = useTranslation();

  return (
    <Col className="known-terms text-center px-3">
      <span>
        {t("Terms.declareToAgree")}
        <Link target="_blank" to="/privacidade">
          {" "}
          {t("Terms.privacy")}{" "}
        </Link>
        {t("Terms.andThe")}{" "}
        <Link target="_blank" to="/termos">
          {t("Terms.use")}
        </Link>
        , {t("Terms.includingBackgroundCheckAndFee")}.
      </span>
    </Col>
  );
}
