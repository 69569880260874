/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import bannerDefault from "../../assets/img/producer-banner.jpg";
import thumbnailDefault from "../../assets/img/producer-thumbnail.jpg";
import EventGridContainer from "../../components/Events/EventGridContainer";
import Loader from "../../components/Loader";
import NewApi from "../../services/new-api";
import sendToLogger from "../../Helpers/errorLogger";
import "./index.css";
import { removeCheckoutData } from "../../Helpers/checkout";
import { useAuth } from "../../hooks/useAuth";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 25;

export default function Index() {
  const [events, setEvents] = useState([]);
  const [producer, setProducer] = useState({
    logo: "",
    name: "",
    banner: "",
  });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const eventsContainerRef = useRef(null);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { id, type } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.scrollTo(0, 0);

    setLoading(true);

    const data = {};

    if (type === "organization" || pathname.includes("/organizacoes/")) {
      data.by_organization_slug = id;
    } else {
      data.by_producer = id;
    }

    data.per_page = PAGE_SIZE;
    data.page = currentPage;

    new NewApi()
      .get("events", { params: data })
      .then(async (res) => {
        const newEvents = res.data.data;

        if (newEvents.length > 0) {
          const info = newEvents[0];

          const producer = {
            name: info.user?.name || info.organization?.name || "-",
            logo:
              info.user?.picture || info.organization?.logo || thumbnailDefault,
            banner: info.organization?.banner || bannerDefault,
          };

          setProducer(producer);

          if (user) {
            await MoengageService.createEvent({
              type: "event",
              customer_id: user.id,
              actions: [
                {
                  action: "View Producer",
                  attributes: {
                    ...producer,
                  },
                },
              ],
              platform: "web",
            });
          }
        }

        setEvents([...events, ...newEvents]);

        setLastPage(res.data.meta.last_page);
      })
      .catch((error) => {
        sendToLogger(error);
      })
      .finally(() => {
        setLoading(false);
        if (eventsContainerRef.current && currentPage > 1) {
          eventsContainerRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });

    removeCheckoutData();
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return loading ? (
    <>
      <div className="loading-container" />
      <Loader />
    </>
  ) : (
    <>
      <div
        id="productor-head"
        className="background"
        style={{ backgroundImage: `url(${producer.banner})` }}
      />
      <div
        className="productor-avatar background"
        style={{ backgroundImage: `url(${producer.logo})` }}
      />

      <h3 className="text-center proctor-name">{producer.name}</h3>
      <div id="main-events" className="container">
        <div className="events-list" ref={eventsContainerRef}>
          {events.length > 0 ? (
            <>
              <div className="title-productor text-center">
                <h5 className="text-center">
                  {t("EventsProducer.upcomingEvents")}
                </h5>
              </div>
              <div className="event-list-row">
                <EventGridContainer events={events} />
              </div>
              {currentPage !== lastPage && (
                <div>
                  <button className="load-more-button" onClick={handleLoadMore}>
                    {t("EventsProducer.seeMore")}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="title-productor ajust-center  text-center">
              <h2 className="text-center">
                {t("EventsProducer.noEventsFoundProducer")}
              </h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
