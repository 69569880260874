import React, { Fragment } from "react";
import NewButton from "../../../../../components/NewButton";

export default function ConcludedSection({ handleRedirect }) {
  return (
    <Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <NewButton
          className="primary-contained"
          onClick={() => handleRedirect("myTickets")}
          content={<Fragment>Ver meus ingressos</Fragment>}
        />
      </div>
    </Fragment>
  );
}
