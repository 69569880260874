import React, { Fragment, useState } from "react";
import "./styles.css";

import Clock from "../../../assets/svg/Clock.svg";
import ValidTicket from "../../../assets/svg/Valid Ticket.svg";
import InvalidTicket from "../../../assets/svg/Invalid Ticket.svg";

import { Card, Image } from "react-bootstrap";
import DownloadApp from "../../../components/DownloadApp";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { getSessionStorageItems } from "../../../services/utils";
import QRCodeSection from "../../../components/QRCodeSection";
import { Trans, useTranslation } from "react-i18next";
import PixSection from "./components/PixSection";
import PicpaySection from "./components/PicpaySection";
import ConcludedSection from "./components/ConcludedSection";
import ErrorSection from "./components/ErrorSection";
import InstructionsSection from "./components/InstructionsSection";
import TipSection from "./components/TipSection";
import ProcessingSection from "./components/ProcessingSection";
import BoletoSection from "./components/BoletoSection";

const checkoutStatus = {
  pending: {
    icon: Clock,
    title: "CheckoutStatus.pending.title",
  },
  processing: {
    icon: Clock,
    title: "CheckoutStatus.processing.title",
  },
  concluded: {
    icon: ValidTicket,
    title: "CheckoutStatus.concluded.title",
  },
  error: {
    icon: InvalidTicket,
    title: "CheckoutStatus.error.title",
  },
};

const sessionItems = [
  "order_token",
  "pix-code",
  "picpay-url",
  "picpay-code",
  "error-message",
  "boleto",
  "slug",
  "type",
];

const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function ResultCheckoutPage() {
  const {
    token,
    pix,
    picpayUrl,
    picpayCode,
    errorMessage,
    boleto,
    slug,
    type,
  } = getSessionStorageItems(sessionItems);
  const { user } = useAuth();
  const { status } = useParams();
  const { t } = useTranslation();
  const [isCopy, setIsCopy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleRedirect = (action) => {
    const urls = {
      picpay: picpayUrl,
      myTickets: "/meus-ingressos",
      myOrders: "/meus-pedidos",
      tryAgain: `/eventos/${slug}`,
      boleto,
    };
    window.location.href = urls[action];
  };

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  const handleCopyPix = () => {
    navigator.clipboard.writeText(pix);
    setIsCopy(true);

    setTimeout(() => {
      setIsCopy(false);
    }, 2000);
  };

  const checkoutButtons = {
    pix: (
      <Fragment>
        <PixSection pix={pix} isCopy={isCopy} handleCopy={handleCopyPix} />
      </Fragment>
    ),
    picpay: (
      <Fragment>
        <PicpaySection code={picpayCode} handleRedirect={handleRedirect} />
      </Fragment>
    ),
    concluded: (
      <Fragment>
        <ConcludedSection handleRedirect={handleRedirect} />
      </Fragment>
    ),
    processing: (
      <Fragment>
        <ProcessingSection handleRedirect={handleRedirect} status={status} />
      </Fragment>
    ),
    boleto: (
      <Fragment>
        <BoletoSection handleRedirect={handleRedirect} />
      </Fragment>
    ),
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-center mt-3">
        <Card className={`checkout-card ${status}`}>
          <Card.Body id="checkout-content">
            <div className="d-flex justify-content-center icon-header">
              <Image src={checkoutStatus[status].icon} alt="Clock" />
            </div>

            <div className="d-flex justify-content-center align-items-center flex-column">
              <h4 className={`checkout-title ${status}-title`}>
                {!type && status === "processing"
                  ? t("CheckoutStatus.processing.title", {
                      firstName: user.first_name,
                      token: token.toUpperCase(),
                    })
                  : t(checkoutStatus[status].title, {
                      firstName: user.first_name,
                      token: token.toUpperCase(),
                    })}
              </h4>

              <p className={`checkout-text ${status}-text`}>
                {status === "pending" ? (
                  <Trans
                    i18nKey={`CheckoutStatus.pending.subtitle.${type}`}
                    components={{ strong: <strong /> }}
                  />
                ) : (
                  t(isWhitelabel ?  `CheckoutStatus.${status}.subtitleWhitelabel` : `CheckoutStatus.${status}.subtitleZig` )
                )}
              </p>

              <QRCodeSection code={pix || picpayCode} isPicpay={!!picpayCode} />

              {(type === "pix" || type === "picpay") && (
                <p className="checkout-text">
                  <Trans
                    i18nKey={`CheckoutStatus.pending.instructions.${type}`}
                    components={{ strong: <strong /> }}
                  />
                </p>
              )}

              {checkoutButtons[type || status]}

              <InstructionsSection
                errorMessage={errorMessage}
                status={status}
                type={type}
              />

              <TipSection status={status} type={type} />

              <ErrorSection handleRedirect={handleRedirect} status={status} />
            </div>
          </Card.Body>
        </Card>
      </div>

      {!isWhitelabel && (
        <div className="m-3">
          <DownloadApp isOpen={isOpen} handleOpenModal={handleOpenModal} />
        </div>
      )}
    </Fragment>
  );
}
