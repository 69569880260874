export function ticketNomenclatureInSingular(nomenclature) {
  switch (nomenclature.toLowerCase()) {
    case "doações":
      return "Doação";
    case "inscrições":
      return "Inscrição";
    case "couverts":
      return "Couvert";
    default:
      return "Ingresso";
  }
}

export function orderStatus(status, t) {
  switch (status.toLowerCase()) {
    case "finalizado":
      return t("MyTickets.completed");
    case "pré-autorizada":
      return t("MyTickets.preauthorized");
    default:
      return t("MyTickets.refunded");
  }
}

export function gatewayErrorMessagesByType(type) {
  switch (type) {
    case "high_risk_transaction_declined":
      return "A transação não foi autorizada devido a problemas nos dados informados.";
    case "internal_error":
      return "Ocorreu um erro interno ao realizar a sua transação.";
    case "card_error":
      return "Não foi possível processar os dados do seu cartão de crédito. Verifique as informações fornecidas e tente novamente.";
    case "buyer_error":
      return "Não foi possível processar as informações do comprador. Verifique os dados fornecidos e tente novamente.";
    case "card_declined":
      return "A transação foi recusada devido a informações incorretas do cartão de crédito.";
    case "service_request_timeout":
      return "Não foi possível estabelecer comunicação com o serviço de pagamento, tente novamente.";
    case "acquirer":
      return "Não foi possível completar a sua compra. Verifique se você digitou as informações corretas do seu cartão e tente novamente.";
    case "antifraud":
      return "Não foi possível completar a sua compra. Verifique se você digitou as informações do comprador corretamente e tente novamente.";
    case "not_found":
      return "Ocorreu um erro ao processar o pagamento, tente novamente.";
    case "3ds_failure":
      return "Não foi possível completar a validação dos dados do comprador com as informações fornecidas. Verifique se você digitou as informações corretamente ou tente mudar a forma de pagamento.";
    case "3ds_debit_failure":
      return "Não foi possível prosseguir com o pagamento de débito com o banco/emissor do seu cartão. Tente outra forma de pagamento.";
    case "3ds_change_failure":
      return "Não foi possível prosseguir com a forma de pagamento selecionada. Tente outra forma de pagamento para prosseguir.";
    case "3ds_challenge_failure":
      return "Não foi possível prosseguir com o pagamento pois o dados solicitados não foram informados. Repita a operação";
    default:
      return "Ocorreu uma falha ao realizar o seu pagamento.";
  }
}

const sizes = {
  mobile: "321px",
  mobileL: "500px",
  tablet: "768px",
  desktop720p: "1280px",
  desktop1080p: "1920px",
  desktop4k: "3000px",
};

export const breakPoints = {
  mobile: `(min-width: ${sizes.mobile})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  desktop720p: `(min-width: ${sizes.desktop720p})`,
  desktop1080p: `(min-width: ${sizes.desktop1080p})`,
  desktop4k: `(min-width: ${sizes.desktop4k})`,
};
export const COLORS = {
  blue: "#0050C3",
  purple: "#0050C3",
  black: "#000022",
  gray: "#6e84a3",
  darkgray: "#1b3051",
  white: "#FFFFFF",
  red: "#C30000",
};

export const FONTS_SIZE = {
  fontSize0: "1rem",
  fontSize1: "1.3rem",
  fontSize2: "1.5rem",
  fontSize3: "2.5rem",
  fontSize4: "2.8rem",
  fontSize5: "3.5rem",
};

export const FONT = "Inter";

export const SPACINGS = {
  space0: "4px",
  space1: "8px",
  space2: "12px",
  space3: "16px",
  space4: "24px",
  space5: "35px",
  space6: "50px",
};

export const genderOptions = [
  { value: "", label: "select" },
  { value: "FEMALE", label: "female" },
  { value: "MALE", label: "male" },
  { value: "NON_BINARY", label: "nonBinary" },
];

export const OPACITY = {
  normal: "1",
  light: "0.4",
};

export const STATE_NAMES = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const transformStateName = (input) => {
  let inputUpperCase;

  if (typeof input !== "string") {
    return null;
  } else {
    inputUpperCase = input.toUpperCase();
  }

  return (
    STATE_NAMES[inputUpperCase] ||
    Object.keys(STATE_NAMES).find(
      (key) => STATE_NAMES[key].toUpperCase() === inputUpperCase
    ) ||
    null
  );
};

export const SponsorCategoryDict = {
  SPONSOR: "PATROCINADOR",
  PRODUCER: "REALIZAÇÃO",
  CO_PRODUCER: "CORREALIZAÇÃO",
  PARTNERSHIP: "PARCERIA",
  INSTITUTIONAL_PARTNERSHIP: "PARCERIA INSTITUCIONAL",
  SUPPORT: "APOIO",
  PRESENTED_BY: "APRESENTADO POR",
};

export const setCookie = (
  cookieName,
  dataBuffer,
  maxAgeSeconds,
  path = "/"
) => {
  const base64Data = Buffer.from(JSON.stringify(dataBuffer)).toString("base64");
  const cookieString = `${encodeURIComponent(cookieName)}=${encodeURIComponent(
    base64Data
  )}; max-age=${maxAgeSeconds}; path=${path};`;
  document.cookie = cookieString;
};

export const getDecodedCookie = (name, code) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${name}=`));
  if (cookieValue) {
    const value = decodeURIComponent(cookieValue.split("=")[1]);
    const decodedValue = JSON.parse(
      Buffer.from(value, "base64").toString("utf-8")
    );
    return decodedValue.code === code ? decodedValue : null;
  }
};

export const getCookieEncrypted = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${name}=`));
  if (cookieValue) {
    const value = decodeURIComponent(cookieValue.split("=")[1]);
    const decodedValue = JSON.parse(
      Buffer.from(value, "base64").toString("utf-8")
    );
    return decodedValue;
  }
};

export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(`${name}=`));
  if (cookieValue) {
    return cookieValue.split("=")[1];
  }
  return null;
};

export const LOCATION_DATA_EXPIRATION_IN_DAYS = 90;
export const IP_ERROR_TIMEOUT = 5000;

export const findDescription = (code, discount) => {
  const foundDiscount = discount?.find((item) => item.code === code);
  return foundDiscount?.description || "";
};

export const paymentTypeDictionary = {
  "Não realizado": "notCompleted",
  PIX: "PIX",
  picpay: "picpay",
  Cortesia: "courtesy",
  "Cartão de Crédito": "creditCard",
  "Cartão de Débito": "debitCard",
  Dinheiro: "cash",
  Boleto: "boleto",
};
