/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { MetaTags } from "react-meta-tags";
import { removeCheckoutData } from "../../Helpers/checkout.js";
import sendToLogger from "../../Helpers/errorLogger.js";
import Carrousel from "../../components/Carrousel";
import Destaques from "../../components/Destaques";
import PaginatedEvents from "../../components/PaginatedEvents";
import { useEvent } from "../../hooks/useEvent.jsx";
import { useSearch } from "../../hooks/useSearch.jsx";
import KondutoService from "../../services/konduto";
import "./index.css";
import { useLocation } from "../../hooks/useLocation.jsx";
import { transformStateName } from "../../services/constants";
import { group } from "radash";
import { useTranslation } from "react-i18next";
import { loadingState } from "../../atoms/loading.js";
import { useRecoilValue } from "recoil";
import EventLoader from "../../components/EventLoader/index.js";
import DownloadApp from "../../components/DownloadApp/index.js";

const ITEMS_PER_PAGE = 8;
const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

export default function Index({ history }) {
  const { populateEventsList, setLoadingEvents, events } = useEvent();
  const { selectedState, updateSearch } = useSearch();
  const { location } = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searched, setSearched] = useState([]);
  const [destaques, setDestaques] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [destaquesTop, setDestaquesTop] = useState([]);
  const [verificationCodes, setVerificationCodes] = useState([]);
  const previousAccess = localStorage.getItem("selectedState");
  const eventItems = searched.length > 0 ? searched : events.events;
  const loadingEvents = useRecoilValue(loadingState);

  useEffect(() => {
    let newState = transformStateName(location.userData?.region);
    if (previousAccess) {
      newState = previousAccess;
    }
    if (newState && newState !== "Todos os estados") {
      updateSearch("state", newState);
      const newURL = `?st=${newState}`;
      window.history.pushState({ path: newURL }, "", newURL);
    }
    document.title = t("CommonExpressions.pageTitle");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      ReactPixel.pageView();
    }, 1500);

    removeCheckoutData();
    KondutoService.sendEvent("page", "home");
  }, [selectedState, location.userData]);

  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        setLoadingEvents(true);
        await populateEventsList();
      } catch (error) {
        console.error("Error fetching events.");
      }
    };

    if (events && events?.events) {
      setCurrentPage(1);
      handleVerificationCodes(events.events);
      processEventsData(events);
      setLoadingEvents(false);
    } else {
      fetchEventsData();
    }
  }, [events.events?.length, selectedState]);

  const handleVerificationCodes = (events) => {
    setVerificationCodes(
      events
        .filter(
          ({ verification_code, fb_domain_verification_code }) =>
            !!verification_code || !!fb_domain_verification_code
        )
        .map(
          ({ verification_code, fb_domain_verification_code }) =>
            verification_code || fb_domain_verification_code
        )
    );
  };

  const groupAndSortEvents = (data) => {
    const groupedEvents = group(data, (event) => {
      const state =
        event.event?.event_location?.state ?? event.event_location?.state;
      return transformStateName(selectedState) === state ? "same" : "other";
    });

    const sameEvents = groupedEvents.same || [];
    const otherEvents = groupedEvents.other || [];

    return sameEvents.concat(otherEvents);
  };

  const handleEvents = (eventsData, middleHighlights, topHighlights) => {
    try {
      setLoading(true);
      setSearched(groupAndSortEvents(eventsData));
      setDestaques(groupAndSortEvents(middleHighlights));
      setDestaquesTop(groupAndSortEvents(topHighlights));
    } catch (err) {
      sendToLogger(err);
    } finally {
      setLoading(false);
    }
  };

  function processEventsData(events) {
    try {
      setLoading(true);
      setDestaques(events.middle_highlights);
      setDestaquesTop(events.top_highlights);

      if (!selectedState && !location.userData) {
        setSearched(events.events);
      }
      if (selectedState || location.userData) {
        handleEvents(
          events.events,
          events.middle_highlights,
          events.top_highlights
        );
      }
    } catch (error) {
      sendToLogger(error);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MetaTags>
        {verificationCodes.map((item, index) => (
          <meta
            key={index}
            name="facebook-domain-verification"
            content={item}
          />
        ))}
      </MetaTags>

      {loadingEvents || loading ? (
        <div>
          <EventLoader />
        </div>
      ) : (
        <React.Fragment>
          <div className="d-flex flex-column home-top-container">
            {destaquesTop.length > 0 && <Carrousel highlights={destaquesTop} />}
            {destaques.length > 0 && (
              <div className="secondary-highlights">
                <Destaques events={destaques} history={history} />
              </div>
            )}
          </div>
          <div id="main-bar" />
          <br />
          {!isWhitelabel && (
            <div className="container">
              <DownloadApp isOpen={isOpen} handleOpenModal={handleOpenModal} />
            </div>
          )}
          <br />
          <div id="near-events" className="container">
            <h2
              className="title"
              style={{ textAlign: "center", fontSize: "x-large" }}
            >
              {location.isAcceptedLocation
                ? t("Index.eventsNearYou")
                : t("Index.upcomingEvents")}
            </h2>

            <PaginatedEvents
              events={eventItems?.slice(0, currentPage * ITEMS_PER_PAGE)}
              showLoadMore={currentPage * ITEMS_PER_PAGE <= eventItems?.length}
              onLoadMore={() => setCurrentPage((state) => state + 1)}
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
}
