import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert2";
import Loader from "../../components/Loader";
import ErrorHandler from "../../services/error-handler";
import NewApi from "../../services/new-api";
import sendToLogger from "../../Helpers/errorLogger";
import KondutoService from "../../services/konduto";
import "./index.css";
import { removeCheckoutData } from "../../Helpers/checkout";
import { useTranslation } from "react-i18next";

export default function Index() {
  const nomeEmpresa = process.env.REACT_APP_NAME_EMPRESA;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfPass] = useState("");
  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    removeCheckoutData();
    KondutoService.sendEvent("page", "password_reset");
  }, []);

  async function resetPassword(e) {
    e.preventDefault();
    try {
      let resEmail = await verifyEmail();

      if (resEmail) {
        try {
          let resConf = await verifyPasswords();

          if (resConf) {
            setLoading(true);

            new NewApi()
              .post("passwords/renew", {
                email,
                token,
                password,
                password_confirmation: confirm_password,
              })
              .then(() => successMessage(t("SeatsChart.passwordUpdated")))
              .catch((error) => {
                sendToLogger(error);
                errorMessage(
                  ErrorHandler.handleFormErrors(error.response.data, t)
                );
              })
              .finally(() => setLoading(false));
          }
        } catch (e) {
          sendToLogger(e);
          errorMessage(t("ResetPassword.passwordMismatch"));
        }
      }
    } catch (e) {
      sendToLogger(e);
      errorMessage(t("ResetPassword.informUserEmail"));
    }
  }

  async function verifyPasswords() {
    if (password !== confirm_password) {
      throw new Error(t("ResetPassword.passwordError"));
    } else {
      return true;
    }
  }

  async function verifyEmail() {
    if (!email.length) {
      throw new Error(t("ResetPassword.emailError"));
    } else {
      return true;
    }
  }

  function successMessage(message) {
    swal
      .fire({
        type: "success",
        icon: "success",
        title: t("CommonExpressions.success"),
        text: message,
        footer: nomeEmpresa,
      })
      .then(() => {
        history.push("/");
      });
  }

  function errorMessage(message) {
    swal.fire({
      icon: "error",
      title: t("FaleForm.oops"),
      text: message,
      footer: nomeEmpresa,
    });
  }

  return (
    <>
      {loading ? <Loader></Loader> : null}

      <div className="container">
        <h3 className="text-center">{t("ResetPassword.passwordResetPanel")}</h3>
        <div className="row" id="form-header">
          <div className="col-md-8 col-lg-8 col-xl-8 offset-md-2 offset-lg-2 offset-xl-2">
            <form action="">
              <div className="form-group">
                <div className="form-row">
                  <label htmlFor="">{t("ResetPassword.email")}</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder={t("ResetPassword.confirmEmail")}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <label htmlFor="" className="new-pass">
                      {t("ResetPassword.newPassword")}
                    </label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      placeholder="******************"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <label htmlFor="">
                      {t("ResetPassword.confirmNewPassword")}
                    </label>
                    <input
                      type="password"
                      required
                      className="form-control"
                      placeholder="******************"
                      onChange={(e) => setConfPass(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <button
                      className="btn btn-primary btn-send-reset"
                      onClick={resetPassword}
                    >
                      <i className="fa fa-key"></i>
                      {t("ResetPassword.resetPassword")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
